import { fromJS } from 'immutable'

import { IActionProps } from '../../../types'

import {
  TABLE_DATA_FAILURE,
  TABLE_DATA_REQUEST,
  TABLE_DATA_SUCCESS,
  TABLE_META_PATCH,
  TABLE_META_SET,
} from './constants'

const initialState = fromJS({})

const reducer = (
  state = initialState,
  { type, meta, payload }: IActionProps,
) => {
  const table = meta && meta.table
  const response = payload && payload.response
  switch (type) {
    case TABLE_DATA_REQUEST: {
      return state.setIn([table, 'loading'], true).setIn([table, 'error'], null)
    }

    case TABLE_DATA_SUCCESS: {
      const totalSize = state.getIn([table, 'meta', 'totalSize'])
      if (meta?.totalSize === -1) {
        return state
          .setIn([table, 'data'], response)
          .setIn([table, 'loading'], false)
          .setIn([table, 'error'], null)
          .updateIn([table, 'meta'], (values: any) => {
            return {
              ...values,
              totalSize,
            }
          })
      }
      return state
        .setIn([table, 'data'], response)
        .setIn([table, 'loading'], false)
        .setIn([table, 'error'], null)
        .setIn([table, 'meta'], meta)
    }

    case TABLE_DATA_FAILURE: {
      return state
        .setIn([table, 'request'], false)
        .setIn([table, 'loading'], false)
        .setIn([table, 'error'], payload)
    }

    case TABLE_META_SET:
      return state.setIn([table, 'meta'], payload)

    case TABLE_META_PATCH:
      return state.updateIn([table, 'meta'], (value: any) => ({
        ...value,
        ...payload,
      }))

    default:
      return state
  }
}

export default reducer
