import { get } from 'lodash';
import { change, registerField } from 'redux-form';
import { all, call, delay, put, select, takeEvery } from 'redux-saga/effects';

import {
  closeFlowLoader,
  openFlowLoader,
} from 'components/Flows/Loader/actions';
import openNotification from 'components/Notification';

import UPLOAD_API from './api/rest';
import {
  REMOVE_FILE_REQUEST,
  UPLOAD_FILE_REQUEST,
  REMOVE_ALL_FILES_REQUEST,
} from './constants';
import EOM_API from '../../../modules/Constructor/api';

function* uploadFile({
  payload: { file, form, fieldId, eomId, formData },
}: any) {
  yield put(openFlowLoader({ id: [form, fieldId] }));
  try {
    const { data } = yield call(UPLOAD_API.uploadFile, file, eomId, formData);
    const { values } = yield select((state: any) => state.form[form]);
    const response = get(values, 'files');
    if (response === undefined) {
      yield put(registerField(form, fieldId, 'Field'));
      yield put(change(form, fieldId, [response]));
    } else {
      yield put(change(form, fieldId, [...response, data]));
    }
  } catch (error) {
    openNotification({
      type: 'error',
      message: 'Загрузить файл не удалось.',
      placement: 'topLeft',
    });
  }
  yield put(closeFlowLoader({ id: [form, fieldId] }));
}

function* removeFile({ payload: { form, fieldId, eomId, fileId } }: any) {
  yield put(openFlowLoader({ id: [form, fieldId] }));
  try {
    yield call(UPLOAD_API.removeFile, fileId, eomId);
    const { data } = yield call(EOM_API.getEOMFiles, eomId);
    const { values } = yield select((state: any) => state.form[form]);
    const response = get(values, 'files');
    if (response === undefined) {
      yield put(registerField(form, fieldId, 'Field'));
      yield put(change(form, fieldId, data));
    } else {
      yield put(change(form, fieldId, data));
    }
  } catch (error) {
    openNotification({
      type: 'error',
      message: 'Удалить файл не удалось.',
      placement: 'topLeft',
    });
  }
  yield put(closeFlowLoader({ id: [form, fieldId] }));
}

function* removeAllFiles({ payload: { form, fieldId, eomId } }: any) {
  yield put(openFlowLoader({ id: [form, fieldId] }));
  try {
    yield call(UPLOAD_API.removeAllFiles, eomId);
    const { data } = yield call(EOM_API.getEOMFiles, eomId);
    const { values } = yield select((state: any) => state.form[form]);
    const response = get(values, 'files');
    if (response === undefined) {
      yield put(registerField(form, fieldId, 'Field'));
      yield put(change(form, fieldId, data));
    } else {
      yield put(change(form, fieldId, data));
    }
  } catch (error) {
    openNotification({
      type: 'error',
      message: 'Удалить файл не удалось.',
      placement: 'topLeft',
    });
  }
  yield put(closeFlowLoader({ id: [form, fieldId] }));
}

function* Saga() {
  yield all([
    takeEvery(UPLOAD_FILE_REQUEST, uploadFile),
    takeEvery(REMOVE_FILE_REQUEST, removeFile),
    takeEvery(REMOVE_ALL_FILES_REQUEST, removeAllFiles),
  ]);
}

export default Saga;
