import React from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'

import { ILoadingComponent } from './types'

export default ({ size, spin }: ILoadingComponent) => (
  <Spin
    indicator={<LoadingOutlined style={{ fontSize: size }} spin={spin} />}
  />
)
