import { assign } from 'lodash'
import { stopSubmit } from 'redux-form'
import { put, takeLatest } from 'redux-saga/effects'

import openNotification from '../Notification'

function* errorHandler({ error, form, customMessage }: any) {
  const errors = error?.errors
  if (!errors) {
    return
  }
  const haveGlobalErrors =
    Array.isArray(errors) && errors.some((el: any) => el._error)

  if (haveGlobalErrors) {
    yield put(stopSubmit(form, assign({}, ...errors)))
  }
  if (customMessage) {
    openNotification({
      type: 'error',
      message: 'Произошла ошибка',
      description: customMessage,
      placement: 'topLeft',
    })
  }
}

function* Saga() {
  yield takeLatest(
    (action: any) => action.type.includes('_FAILURE'),
    errorHandler,
  )
}

export default Saga
