import React from 'react'
import { Result } from 'antd'

import 'antd/dist/antd.css'

const NotFound = () => (
  <Result
    status="404"
    title="404"
    subTitle="Извините, данной страницы не существует."
  />
)

export default NotFound
