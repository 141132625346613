import { fromJS } from 'immutable'
import { get } from 'lodash'
import { IActionProps } from 'types'

import {
  SET_VALUE_FAILURE,
  SET_VALUE_REQUEST,
  SET_VALUE_SUCCESS,
} from './constants'

const initialState = fromJS({})

const valueSetReducer = (
  state = initialState,
  { type, payload }: IActionProps,
) => {
  switch (type) {
    case SET_VALUE_SUCCESS: {
      const { id, data } = get(payload, 'meta')
      return state.set(id, data)
    }

    case SET_VALUE_REQUEST: {
      return state
    }

    case SET_VALUE_FAILURE: {
      return state
    }

    default:
      return state
  }
}

export default valueSetReducer
