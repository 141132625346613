import { push } from 'connected-react-router/immutable';
import { find, get, isString, size } from 'lodash';
import { AnyAction } from 'redux';
import { change, registerField } from 'redux-form';
import {
  all,
  call,
  delay,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import openNotification from 'components/Notification';

// import { EOM_FORM_TASKS } from '../../../constants/forms'
import { buildXmlSchema } from '../../../utils/buildXML';
import { buildXmlSchemaV2 } from '../../../utils/buildXMLv2';
import EOM_API from '../api';

import { getAllEOMSRequest, getEOMSHistoryRequest } from './actions';
import {
  ADD_ANSWER_TO_TASK_REQUEST,
  ADD_ANSWER_TO_TASK_SELECT_REQUEST,
  ADD_TASK_REQUEST,
  CREATE_EOM_REQUEST,
  DELETE_EOM_REQUEST,
  DELETE_EOMS_REQUEST,
  EDIT_ANSWER_FROM_TASK_REQUEST,
  EDIT_ANSWER_FROM_TASK_SELECT_REQUEST,
  EDIT_EOM_REQUEST,
  EDIT_EOM_TASK_REQUEST,
  GENERATE_CHECKING_XML_REQUEST,
  GENERATE_XML_REQUEST,
  GENERATE_XML_SERVER_REQUEST,
  GENERATE_XML_ZIP_REQUEST,
  GET_ALL_EOMS_REQUEST,
  GET_ANSWER_FROM_TASK_REQUEST,
  GET_ANSWER_FROM_TASK_SELECT_REQUEST,
  GET_EOM_REQUEST,
  GET_EOM_TASK_REQUEST,
  GET_EOMS_HISTORY_REQUEST,
  ADD_EOM_HISTORY_REQUEST,
  ADD_ANSWER_TO_TASK_SELECT_REQUEST_SECOND,
  GET_ANSWER_FROM_TASK_SELECT_REQUEST_SECOND,
  EDIT_ANSWER_FROM_TASK_SELECT_REQUEST_SECOND,
  IMPORT_FROM_BPM_REQUEST,
  GET_STATUS_CHECKING_REQUEST,
} from './constants';

const REST__API = process.env.REACT_APP_REST__API;
const getEomForm = (state: any) => state.form.EOM_FORM;
function* getAllEOMS({
  meta: { table, callback, page: queryPage, size: querySize, search },
}: AnyAction) {
  try {
    // ACHTUNG fix query page from ONE to N (it was from ZERO to N)
    const newPage = queryPage + 1;

    const {
      data: { items, totalCount },
    } = yield call(EOM_API.getAllEOMs, {
      page: newPage,
      size: querySize,
      q: search,
    });
    if (callback) {
      // @ts-ignore
      return yield put(
        callback({
          meta: {
            table,
            page: queryPage,
            size: querySize,
            totalSize: totalCount,
          },
          payload: { response: items },
        }),
      );
    }
  } catch (error) {
    openNotification({
      type: 'error',
      message: 'Получить список EOM не удалось.',
      placement: 'topLeft',
    });
  }
}

function* getEOM({
  payload: { callback, graphId },
  meta: { form },
}: AnyAction) {
  try {
    // @ts-ignore
    const state = yield select();
    const pathArray = state.router.location.pathname.split('/');
    let response;
    // GET EOM version of history
    if (pathArray[4] === 'history' && parseInt(pathArray[5]) > 0) {
      // @ts-ignore
      response = yield call(EOM_API.getEOMHistory, graphId, pathArray[5]);
    } else {
      // @ts-ignore
      response = yield call(EOM_API.getEOM, graphId);
    }
    const { data } = yield call(EOM_API.getEOMFiles, graphId);
    // console.log('getEOM response', response);
    const initData = {
      tasks: get(response, 'data.body.tasks'),
      ...get(response, 'data.body.description'),
      files: data,
      dateCreate: get(response, 'data.dateCreate'),
      dateUpdate: get(response, 'data.dateUpdate'),
    };
    // console.log('getEOM initData', initData);
    if (callback) {
      // @ts-ignore
      return yield put(
        callback({ payload: { response: initData }, meta: { form } }),
      );
    }
  } catch (error) {
    openNotification({
      type: 'error',
      message: 'Получить EOM не удалось.',
      placement: 'topLeft',
    });
  }
}

// const getEomForm1 = (state: any) => state.form.EOM_FORM
function* getEOMSHistory({
  meta: { table, callback, page: queryPage, size: querySize },
}: AnyAction) {
  // ACHTUNG fix query page from ONE to N (it was from ZERO to N)
  const newPage = queryPage + 1;

  // ACHTUNG!!! TODO get eomId for another method
  // @ts-ignore
  const state = yield select();
  const eomIdFromPath = state.router.location.pathname;
  let eomId = 0;
  if (eomIdFromPath !== undefined) {
    eomId = parseInt(eomIdFromPath.split('/')[3]);
  } else {
    openNotification({
      type: 'error',
      message: 'Получить историю EOMа не удалось.',
      placement: 'topLeft',
    });
    // @ts-ignore
    return yield;
  }
  // console.log('eomId =', eomId)
  try {
    const {
      data: { items, totalCount },
    } = yield call(EOM_API.getEOMSHistory, {
      id: eomId,
      page: newPage,
      size: querySize,
    });
    if (callback) {
      // @ts-ignore
      return yield put(
        callback({
          payload: { response: items },
          meta: {
            table,
            page: queryPage,
            size: querySize,
            totalSize: totalCount,
          },
        }),
      );
    }
  } catch (error) {
    openNotification({
      type: 'error',
      message: 'Получить историю EOMа не удалось.',
      placement: 'topLeft',
    });
  }
}

function* addTask({
  payload: { callback, graphId, fields },
  meta: { form, onSuccess },
}: AnyAction) {
  try {
    const { values } = yield select(getEomForm);
    const tasks = get(values, 'tasks');
    if (tasks === undefined || tasks == null) {
      yield put(registerField('EOM_FORM', 'task', 'Field'));
      yield put(change('EOM_FORM', 'tasks', [fields]));
    } else {
      yield put(change('EOM_FORM', 'tasks', [...tasks, fields]));
    }
    if (callback) {
      // @ts-ignore
      return yield put(callback({ payload: {}, meta: { form, onSuccess } }));
    }
  } catch (error) {
    // console.log('addTask error', error)
    openNotification({
      type: 'error',
      message: 'Добавить задание в EOM не удалось.',
      placement: 'topLeft',
    });
  }
}

const getEomFormTask = (state: any) => state.form.EOM_FORM_TASKS;
function* addAnswerToTask({
  payload: { callback, graphId, fields, meta },
  meta: { form, onSuccess },
}: AnyAction) {
  try {
    const { values } = yield select(getEomFormTask);
    const rightAnswers = get(values, meta?.sourceKey || 'rightAnswers');
    // console.log('getEomFormTask', values, rightAnswers);
    if (rightAnswers === undefined) {
      yield put(
        registerField(
          'EOM_FORM_TASKS',
          meta?.sourceKey || 'rightAnswers',
          'Field',
        ),
      );
      yield put(
        change('EOM_FORM_TASKS', meta?.sourceKey || 'rightAnswers', [fields]),
      );
    } else {
      yield put(
        change('EOM_FORM_TASKS', meta?.sourceKey || 'rightAnswers', [
          ...rightAnswers,
          fields,
        ]),
      );
    }
    if (callback) {
      // @ts-ignore
      return yield put(callback({ payload: {}, meta: { form, onSuccess } }));
    }
  } catch (error) {
    openNotification({
      type: 'error',
      message: 'Добавить задание в EOM не удалось.',
      placement: 'topLeft',
    });
  }
}

// ///
// EOM_FORM_TASK_ANSWERS
const getEomFormTaskAnswers = (state: any) => state.form.EOM_FORM_TASK_ANSWERS;
function* addAnswerToTaskSelect({
  payload: { callback, graphId, fields, meta },
  meta: { form, onSuccess },
}: AnyAction) {
  try {
    const { values } = yield select(getEomFormTaskAnswers);
    const rightElements = get(values, meta?.sourceKey || 'elements');
    if (rightElements === undefined) {
      yield put(
        registerField(
          'EOM_FORM_TASK_ANSWERS',
          meta?.sourceKey || 'elements',
          'Field',
        ),
      );
      yield put(
        change('EOM_FORM_TASK_ANSWERS', meta?.sourceKey || 'elements', [
          fields,
        ]),
      );
    } else {
      yield put(
        change('EOM_FORM_TASK_ANSWERS', meta?.sourceKey || 'elements', [
          ...rightElements,
          fields,
        ]),
      );
    }
    if (callback) {
      // @ts-ignore
      return yield put(callback({ payload: {}, meta: { form, onSuccess } }));
    }
  } catch (error) {
    openNotification({
      type: 'error',
      message: 'Добавить задание в EOM не удалось.',
      placement: 'topLeft',
    });
  }
}

// EOM_FORM_TASK_ANSWERS_SELECT_SECOND OKAY
const getEomFormTaskAnswersSecond = (state: any) =>
  state.form.EOM_FORM_TASK_ANSWERS_SELECT;
function* addAnswerToTaskSelectSecond({
  payload: { callback, graphId, fields, meta },
  meta: { form, onSuccess },
}: AnyAction) {
  try {
    const { values } = yield select(getEomFormTaskAnswersSecond);
    const rightElements = get(values, meta?.sourceKey || 'elements');

    // console.log('values,rightElements', values, rightElements);
    if (rightElements === undefined) {
      yield put(
        registerField(
          'EOM_FORM_TASK_ANSWERS_SELECT',
          meta?.sourceKey || 'elements',
          'Field',
        ),
      );
      yield put(
        change('EOM_FORM_TASK_ANSWERS_SELECT', meta?.sourceKey || 'elements', [
          fields,
        ]),
      );
    } else {
      yield put(
        change('EOM_FORM_TASK_ANSWERS_SELECT', meta?.sourceKey || 'elements', [
          ...rightElements,
          fields,
        ]),
      );
    }
    if (callback) {
      // @ts-ignore
      return yield put(callback({ payload: {}, meta: { form, onSuccess } }));
    }
  } catch (error) {
    openNotification({
      type: 'error',
      message: 'Добавить задание в EOM не удалось.',
      placement: 'topLeft',
    });
  }
}

// !!!
function* getAnswerFromETask({
  payload: { callback, graphId, meta },
  meta: { form },
}: AnyAction) {
  try {
    const { values } = yield select(getEomFormTask);
    const response = get(values, meta?.sourceKey || 'rightAnswers');
    // console.log('getAnswerFromETask', values, response);
    if (callback) {
      // @ts-ignore
      return yield put(
        callback({ payload: { response: response[graphId] }, meta: { form } }),
      );
    }
  } catch (error) {
    openNotification({
      type: 'error',
      message: 'Получить ответ не удалось.',
      placement: 'topLeft',
    });
  }
}
// //
function* getAnswerFromETaskSelect({
  payload: { callback, graphId, meta },
  meta: { form },
}: AnyAction) {
  try {
    const { values } = yield select(getEomFormTaskAnswers);
    const response = get(values, meta?.sourceKey || 'elements');
    // console.log('getAnswerFromETaskSelect', values, response);
    if (callback) {
      // @ts-ignore
      return yield put(
        callback({ payload: { response: response[graphId] }, meta: { form } }),
      );
    }
  } catch (error) {
    openNotification({
      type: 'error',
      message: 'Получить ответ не удалось.',
      placement: 'topLeft',
    });
  }
}

function* getAnswerFromETaskSelectSecond({
  payload: { callback, graphId, meta },
  meta: { form },
}: AnyAction) {
  try {
    const { values } = yield select(getEomFormTaskAnswersSecond);
    const response = get(values, meta?.sourceKey || 'elements');

    // console.log('getAnswerFromETaskSelectSecond ', values, response);
    if (callback) {
      // @ts-ignore
      return yield put(
        callback({ payload: { response: response[graphId] }, meta: { form } }),
      );
    }
  } catch (error) {
    openNotification({
      type: 'error',
      message: 'Получить ответ не удалось.',
      placement: 'topLeft',
    });
  }
}

function* deleteEom({ payload: { graphId } }: AnyAction) {
  try {
    yield call(EOM_API.deleteEom, graphId);
    yield put(push('/'));
    openNotification({
      type: 'success',
      message: 'Еом успешно удален',
    });
  } catch (error) {
    openNotification({
      type: 'error',
      message: 'Удалить EOM не удалось.',
      placement: 'topLeft',
    });
  }
}

function* deleteEoms({ payload: { fields }, meta }: AnyAction) {
  try {
    yield call(EOM_API.deleteEoms, fields);
    openNotification({
      type: 'success',
      message: 'Еом успешно удален',
      placement: 'topLeft',
    });
    yield put(getAllEOMSRequest({ meta }));
  } catch (error) {
    openNotification({
      type: 'error',
      message: 'Удалить EOM не удалось.',
      placement: 'topLeft',
    });
  }
}

function* createEom({
  payload: { callback, fields },
  meta: { form, onSuccess },
}: AnyAction) {
  try {
    // @ts-ignore
    const response = yield call(EOM_API.createEom, fields);
    if (response.data.id) {
      yield put(push(`/constructor/edit/${response.data.id}`));
    }
    if (callback) {
      // @ts-ignore
      return yield put(
        callback({ payload: { response }, meta: { form, onSuccess } }),
      );
    }
  } catch (error) {
    openNotification({
      type: 'error',
      message: 'Создать EOM не удалось.',
      placement: 'topLeft',
    });
  }
}

function* editEom({
  payload: { callback, graphId, fields },
  meta: { form, onSuccess },
}: AnyAction) {
  // console.log(fields);

  // set showSolutionForStudent && showSolutionForTeacher by default
  if (Array.isArray(fields.tasks) && fields.tasks.length > 0) {
    for (let i = 0; i < fields.tasks.length; ++i) {
      const element = fields.tasks[i];
      element.showSolutionForStudent = true;
      element.showSolutionForTeacher = true;
    }
  }

  // if duration is empty string then set duration = 0
  if (fields.description.duration === '') fields.description.duration = 0;
  // set fields.description.duration=null if fields.description.duration===''
  if (fields.description.theme === '') fields.description.theme = null;

  try {
    // @ts-ignore
    const response = yield call(EOM_API.editEom, graphId, fields);
    const { data } = yield call(EOM_API.getEOMFiles, graphId);
    const initData = {
      tasks: get(response, 'data.body.tasks'),
      ...get(response, 'data.body.description'),
      files: data,
    };
    if (callback) {
      // @ts-ignore
      return yield put(
        callback({
          payload: { response: initData },
          meta: { form, onSuccess },
        }),
      );
    }
  } catch (error) {
    openNotification({
      type: 'error',
      message: 'Изменить EOM не удалось.',
      placement: 'topLeft',
    });
  }
}

function* getEomTask({
  payload: { callback, graphId },
  meta: { form },
}: AnyAction) {
  try {
    const { values } = yield select(getEomForm);
    const response = find(get(values, 'tasks'), { id: graphId });
    if (callback) {
      // @ts-ignore
      return yield put(callback({ payload: { response }, meta: { form } }));
    }
  } catch (error) {
    openNotification({
      type: 'error',
      message: 'Получить таск EOM не удалось.',
      placement: 'topLeft',
    });
  }
}

function* editEomTask({
  payload: { callback, graphId, fields },
  meta: { form, onSuccess },
}: AnyAction) {
  try {
    const { values } = yield select(getEomForm);
    const tasks = get(values, 'tasks');
    const newTasks = tasks.map((obj: any) => {
      if (obj.id === graphId) {
        return { ...obj, ...fields };
      }
      return obj;
    });

    yield put(change('EOM_FORM', 'tasks', newTasks));
    if (callback) {
      // @ts-ignore
      return yield put(
        callback({
          payload: { response: newTasks },
          meta: { form, onSuccess },
        }),
      );
    }
  } catch (error) {
    openNotification({
      type: 'error',
      message: 'Изменить EOM не удалось.',
      placement: 'topLeft',
    });
  }
}

function* editAnswerFromTask({
  payload: { callback, graphId, fields, meta },
  meta: { form, onSuccess },
}: AnyAction) {
  try {
    const { values } = yield select(getEomFormTask);
    let tasks = get(values, meta?.sourceKey || 'rightAnswers');

    let newTasks;
    if (isString(fields)) {
      tasks.splice(graphId, 1);
      newTasks = [...tasks, fields];
    } else {
      newTasks = tasks.map((obj: any, key: number) => {
        if (key === graphId) {
          return { ...obj, ...fields };
        }
        return obj;
      });
    }

    yield put(
      change('EOM_FORM_TASKS', meta?.sourceKey || 'rightAnswers', newTasks),
    );
    if (callback) {
      // @ts-ignore
      return yield put(
        callback({
          payload: { response: newTasks },
          meta: { form, onSuccess },
        }),
      );
    }
  } catch (error) {
    openNotification({
      type: 'error',
      message: 'Изменить EOM не удалось.',
      placement: 'topLeft',
    });
  }
}

function* editAnswerFromTaskSelect({
  payload: { callback, graphId, fields, meta },
  meta: { form, onSuccess },
}: AnyAction) {
  try {
    const { values } = yield select(getEomFormTaskAnswers);
    const tasks = get(values, meta?.sourceKey || 'elements');
    const newTasks = tasks.map((elem: any, index: number) => {
      if (index === graphId) {
        // critical change, because because the object was returned
        // old
        // return { ...elem, ...fields }
        // new
        return fields;
      }
      return elem;
    });

    yield put(
      change('EOM_FORM_TASK_ANSWERS', meta?.sourceKey || 'elements', newTasks),
    );
    if (callback) {
      // @ts-ignore
      return yield put(
        callback({
          payload: { response: newTasks },
          meta: { form, onSuccess },
        }),
      );
    }
  } catch (error) {
    openNotification({
      type: 'error',
      message: 'Изменить EOM не удалось.',
      placement: 'topLeft',
    });
  }
}

// second level
function* editAnswerFromTaskSelectSecond({
  payload: { callback, graphId, fields, meta },
  meta: { form, onSuccess },
}: AnyAction) {
  try {
    const { values } = yield select(getEomFormTaskAnswersSecond);
    const tasks = get(values, meta?.sourceKey || 'elements');
    // console.log('editAnswerFromTaskSelectSecond', values, tasks)
    const newTasks = tasks.map((elem: any, index: number) => {
      if (index === graphId) {
        // critical change, because because the object was returned
        // old
        // return { ...elem, ...fields }
        // new
        return fields;
      }
      return elem;
    });

    yield put(
      change(
        'EOM_FORM_TASK_ANSWERS_SELECT',
        meta?.sourceKey || 'elements',
        newTasks,
      ),
    );
    if (callback) {
      // @ts-ignore
      return yield put(
        callback({
          payload: { response: newTasks },
          meta: { form, onSuccess },
        }),
      );
    }
  } catch (error) {
    openNotification({
      type: 'error',
      message: 'Изменить EOM не удалось.',
      placement: 'topLeft',
    });
  }
}

function* generateXML() {
  try {
    const { values } = yield select(getEomForm);
    const { tasks, files = [], ...description } = values;
    let docElem;
    values.version === 1
      ? (docElem = buildXmlSchema(description, tasks, files))
      : (docElem = buildXmlSchemaV2(description, tasks, files));

    const blob = new Blob([new XMLSerializer().serializeToString(docElem)], {
      type: 'text/xml',
    });
    const url = URL.createObjectURL(blob);
    window.open(url, '_target');
    URL.revokeObjectURL(url);
  } catch (error) {
    // console.error(error);
    openNotification({
      type: 'error',
      message: 'Создать XML EOM не удалось.',
      placement: 'topLeft',
    });
  }
}

function* generateXML_ZIP({ payload: { id } }: AnyAction) {
  try {
    const { values } = yield select(getEomForm);
    const { tasks, files, ...description } = values;
    let docElem;
    values.version === 1
      ? (docElem = buildXmlSchema(description, tasks, files))
      : (docElem = buildXmlSchemaV2(description, tasks, files));
    // console.log('docElem', docElem)
    const xml = new XMLSerializer()
      .serializeToString(docElem)
      .replaceAll('&amp;', '&');
    // console.log(xml)
    // @ts-ignore
    const response = yield call(EOM_API.generateZip, { id, xml });
    window.open(`https://${REST__API}${response.data.file}`, '_target');
  } catch (error) {
    // console.error(error);
    openNotification({
      type: 'error',
      message: 'Сгенерировать XML_ZIP EOM не удалось.',
      placement: 'topLeft',
    });
  }
}

function* generateXML_Server({ payload: { id } }: AnyAction) {
  // @ts-ignore
  // const response = yield call(EOM_API.generateZipServer, { id });

  // window.open(`https://${REST__API}${response.data.file}`, '_target');

  // status exit for while
  let status = 0;

  try {
    // @ts-ignore
    yield call(EOM_API.generateExportTsZip, { id });
    while (!status) {
      // @ts-ignore
      const res = yield call(EOM_API.getStatusChecking, {
        id,
      });
      const exportTsState = res.data.stateExportTs;
      // console.log('exportTsState', exportTsState);
      const { values } = yield select(getEomForm);
      const exprortTsStatus = get(values, 'exprortTsStatus');

      if (
        exprortTsStatus?.state === undefined ||
        exprortTsStatus?.state == null
      ) {
        yield put(registerField('EXPORT_TS', 'exprortTsStatus', 'Field'));
        yield put(change('EXPORT_TS', 'exprortTsStatus', exportTsState));
      } else {
        yield put(change('EXPORT_TS', 'exprortTsStatus', exportTsState));
      }
      // console.log('state exportTsStatus', exportTsState);
      switch (exportTsState?.state) {
        case 'WAITING_BUILD_ZIP':
          // openNotification({
          //   type: 'warning',
          //   message: `Ожидает генерации архива (EOM ID: ${id})`,
          //   placement: 'topLeft',
          // });
          status = 0;
          break;
        case 'BUILD_ZIP':
          status = 0;
          break;
        case 'WAITING_EXPORT':
          status = 0;
          break;
        case 'EXPORT':
          status = 0;
          break;
        case 'COMPLETED':
          status = 1;
          break;
        case 'ERROR':
          status = 1;
          break;
        default:
          status = 1;
      }

      yield delay(5000);
    }
  } catch (error) {
    // console.error(error);
    openNotification({
      type: 'error',
      message: 'Сгенерировать XML EOM не удалось.',
      placement: 'topLeft',
    });
  }
}

/*
export const EXPORT_TS_STATE: { [id: string]: string } = {
  WAITING_BUILD_ZIP: 'Ожидает генерации архива',
  BUILD_ZIP: 'Генерации архива',
  WAITING_EXPORT: 'Ожидает начала экспорта',
  EXPORT: 'Экспортируется',
  COMPLETED: 'Завершено',
  ERROR: 'Ошибка'
}
 */
function* generateChecking({
  payload: { id, uploadBpm, uploadBk },
}: AnyAction) {
  // status exit for while
  let status = 0;

  try {
    // @ts-ignore
    yield call(EOM_API.generateChecking, {
      id,
      uploadBpm,
      uploadBk,
    });
    while (!status) {
      // @ts-ignore
      const res = yield call(EOM_API.getStatusChecking, {
        id,
      });
      const exportTsState = res.data.stateExportTs;
      // exportTsState.uploadBpm = uploadBpm;
      // exportTsState.uploadBk = uploadBk;

      const { values } = yield select(getEomForm);
      const exprortTsStatus = get(values, 'exprortTsStatus');

      if (
        exprortTsStatus?.state === undefined ||
        exprortTsStatus?.state == null
      ) {
        yield put(registerField('EXPORT_TS', 'exprortTsStatus', 'Field'));
        yield put(change('EXPORT_TS', 'exprortTsStatus', exportTsState));
      } else {
        yield put(change('EXPORT_TS', 'exprortTsStatus', exportTsState));
      }
      // console.log('state exportTsStatus', exportTsState);
      switch (exportTsState?.state) {
        case 'WAITING_BUILD_ZIP':
          // openNotification({
          //   type: 'warning',
          //   message: `Ожидает генерации архива (EOM ID: ${id})`,
          //   placement: 'topLeft',
          // });
          status = 0;
          break;
        case 'BUILD_ZIP':
          status = 0;
          break;
        case 'WAITING_EXPORT':
          status = 0;
          break;
        case 'EXPORT':
          status = 0;
          break;
        case 'COMPLETED':
          status = 1;
          break;
        case 'ERROR':
          status = 1;
          break;
        default:
          status = 1;
      }

      yield delay(5000);
    }
  } catch (error) {
    // console.error('generateChecking', error)
    openNotification({
      type: 'error',
      message: `Сгенерировать XML EOM ${id} не удалось!!!`,
      placement: 'topLeft',
    });
  }
}

function* getStatusChecking({ payload: { id } }: AnyAction) {
  try {
    // @ts-ignore
    const res = yield call(EOM_API.getStatusChecking, {
      id,
    });
    const exportTsState = res.data.stateExportTs;
    // console.log('state exportTsStatus', res);
    const { values } = yield select(getEomForm);
    const exprortTsStatus = get(values, 'exprortTsStatus');

    if (
      exprortTsStatus?.state === undefined ||
      exprortTsStatus?.state == null
    ) {
      yield put(registerField('EXPORT_TS', 'exprortTsStatus', 'Field'));
      yield put(change('EXPORT_TS', 'exprortTsStatus', exportTsState));
    } else {
      yield put(change('EXPORT_TS', 'exprortTsStatus', exportTsState));
    }
  } catch (error) {
    openNotification({
      type: 'error',
      message: `Получить статус последнего эспорта EOM ${id} не удалось!`,
      placement: 'topLeft',
    });
  }
}

const getStateImportFromBpm = (state: any) => state?.form?.IMPORT_FROM_BPM || 0;
function* importFromBpm({ payload: { codeEom } }: AnyAction) {
  try {
    // @ts-ignore
    const res = yield call(EOM_API.getStatusImportFromBpm, {
      codeEom,
    });
    const stateImportBpm: {
      importFromBpmEomId: number | null;
      importFromBpmError: string | null;
      importFromBpmState: string | null;
    } = {
      importFromBpmEomId: null,
      importFromBpmError: null,
      importFromBpmState: null,
    };
    if (res.data?.stateImportBpm?.eomId) {
      stateImportBpm.importFromBpmEomId = res.data?.stateImportBpm?.eomId;
    }

    if (res.data?.stateImportBpm?.error) {
      stateImportBpm.importFromBpmError = res.data?.stateImportBpm?.error;
    }

    if (res.data?.stateImportBpm?.state) {
      stateImportBpm.importFromBpmState = res.data?.stateImportBpm?.state;
    }
    const { values } = yield select(getStateImportFromBpm);
    const _stateImportBpm_ = get(values, 'stateImportBpm');

    if (_stateImportBpm_ === undefined || _stateImportBpm_ == null) {
      yield put(registerField('IMPORT_FROM_BPM', 'stateImportBpm', 'Field'));
      yield put(change('IMPORT_FROM_BPM', 'stateImportBpm', stateImportBpm));
    } else {
      yield put(change('IMPORT_FROM_BPM', 'stateImportBpm', stateImportBpm));
    }

    // console.log('res', 1);
  } catch (error) {
    // console.log('saga error', error);
    openNotification({
      type: 'error',
      message: `Импортировать ЕОМ не удалось!!!`,
      placement: 'topLeft',
    });
  }
}

function* addEOMHistory({ payload: { id } }: AnyAction) {
  try {
    // @ts-ignore
    const response = yield call(EOM_API.addEOMHistory, { id });
  } catch (error) {
    openNotification({
      type: 'error',
      message: `Сохранить EOM ${id} в историю не удалось!`,
      placement: 'topLeft',
    });
  }
}

function* Saga() {
  yield all([
    takeEvery(GET_ALL_EOMS_REQUEST, getAllEOMS),
    takeEvery(GET_EOMS_HISTORY_REQUEST, getEOMSHistory),
    takeEvery(ADD_EOM_HISTORY_REQUEST, addEOMHistory),
    takeLatest(GET_EOM_REQUEST, getEOM),
    takeLatest(DELETE_EOM_REQUEST, deleteEom),
    takeLatest(DELETE_EOMS_REQUEST, deleteEoms),
    takeLatest(CREATE_EOM_REQUEST, createEom),
    takeLatest(EDIT_EOM_REQUEST, editEom),
    // FORMS
    takeEvery(ADD_TASK_REQUEST, addTask),
    takeLatest(GET_EOM_TASK_REQUEST, getEomTask),
    takeLatest(EDIT_EOM_TASK_REQUEST, editEomTask),
    takeEvery(ADD_ANSWER_TO_TASK_REQUEST, addAnswerToTask),
    takeEvery(GET_ANSWER_FROM_TASK_REQUEST, getAnswerFromETask),
    takeEvery(EDIT_ANSWER_FROM_TASK_REQUEST, editAnswerFromTask),
    //
    takeEvery(ADD_ANSWER_TO_TASK_SELECT_REQUEST, addAnswerToTaskSelect),
    takeEvery(GET_ANSWER_FROM_TASK_SELECT_REQUEST, getAnswerFromETaskSelect),
    takeEvery(EDIT_ANSWER_FROM_TASK_SELECT_REQUEST, editAnswerFromTaskSelect),

    // second level
    takeEvery(
      ADD_ANSWER_TO_TASK_SELECT_REQUEST_SECOND,
      addAnswerToTaskSelectSecond,
    ),
    takeEvery(
      GET_ANSWER_FROM_TASK_SELECT_REQUEST_SECOND,
      getAnswerFromETaskSelectSecond,
    ),
    takeEvery(
      EDIT_ANSWER_FROM_TASK_SELECT_REQUEST_SECOND,
      editAnswerFromTaskSelectSecond,
    ),

    // XML
    takeLatest(GENERATE_XML_REQUEST, generateXML),
    takeLatest(GENERATE_XML_ZIP_REQUEST, generateXML_ZIP),
    takeLatest(GENERATE_XML_SERVER_REQUEST, generateXML_Server),
    takeLatest(GENERATE_CHECKING_XML_REQUEST, generateChecking),
    takeLatest(IMPORT_FROM_BPM_REQUEST, importFromBpm),
    takeLatest(GET_STATUS_CHECKING_REQUEST, getStatusChecking),
  ]);
}

export default Saga;
