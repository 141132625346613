import * as Sentry from '@sentry/browser'
import axios, { AxiosPromise, AxiosRequestConfig } from 'axios'
import { get } from 'lodash'

import { Services } from 'constants/services'

interface IRequest {
  service: Services
}

export const REST__API = process.env.REACT_APP_REST__API
const currEnv = process.env.REACT_APP_ENV

let repeatCount = 1
const maxRepeatCount = 3

interface IMakeRequest {
  repeatRequestId: null | string
}

export const makeRequest = (
  options: AxiosRequestConfig & IMakeRequest,
): AxiosPromise => {
  return axios(options)
    .then((response) => {
      if (![200, 201, 204].includes(response.status)) {
        throw response
      }
      return response.data
    })
    .catch((error) => {
      const code = get(error, ['response', 'status'])
      const message = get(error, ['message'])
      if (![401].includes(code) || ![403].includes(code)) {
        Sentry.withScope((_scope: any) => {
          _scope.setTag('code', code)
          _scope.setTag('APP_ENV', `${REST__API}`)
          _scope.setExtra('error', error)
          Sentry.captureMessage(`[${currEnv?.toUpperCase()}] ${message}`)
        })
      }
      if (code === 502 && repeatCount < maxRepeatCount) {
        repeatCount++
        return makeRequest(options)
      }
      return Promise.reject(error?.response?.data)
    })
}
export function request({
  method,
  url: requestUrl,
  data,
  params,
}: AxiosRequestConfig & IRequest) {
  const url = `https://${REST__API}/api/v1/${requestUrl}`
  const options = { method, url } as AxiosRequestConfig & IMakeRequest

  if (data) {
    options.data = data
  }

  if (params) {
    options.params = params
  }
  return makeRequest(options)
}
