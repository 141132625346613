import React, { FC, ReactNode } from 'react'
import { Switch } from 'react-router-dom'
import routes from 'app/rootRoutes'
import Routes from 'app/Routes'

const App: FC<ReactNode> = () => (
  <Switch>
    {routes.map((item, index) => (
      <Routes key={index} {...item} />
    ))}
  </Switch>
)

export default App
