import { escapeHtml } from './formatters';
export const buildXmlSchemaV2 = (
  descriptionFormGroup: any,
  tasksFormArray: any,
  files: any[],
) => {
  const docElem = document.implementation.createDocument('', '', null);
  const pi = docElem.createProcessingInstruction(
    'xml',
    'version="1.0" encoding="UTF-8"',
  );
  docElem.insertBefore(pi, docElem.firstChild);

  const rootElem = docElem.createElement('t:Eom');
  rootElem.setAttribute('xmlns:t', 'msh.tasktypes/1');
  rootElem.setAttribute(
    'xmlns:xsi',
    'http://www.w3.org/2001/XMLSchema-instance',
  );

  const descriptionElem = docElem.createElement('Description');
  const instrumentsElem = docElem.createElement('Instruments');

  const {
    eomType,
    moduleCode,
    text,
    showStudent,
    calculatorIsNeed,
    duration,
    theme,
  } = descriptionFormGroup;

  const durationElem = docElem.createElement('Duration');
  durationElem.appendChild(
    docElem.createTextNode(duration ? `${duration}` : ''),
  );

  const themeElem = docElem.createElement('Theme');
  themeElem.appendChild(docElem.createTextNode(theme ? `${theme}` : ''));

  const calculatorElem = docElem.createElement('CalculatorIsNeed');
  calculatorElem.appendChild(
    docElem.createTextNode(calculatorIsNeed ? `${calculatorIsNeed}` : `false`),
  );
  instrumentsElem.appendChild(calculatorElem);

  // console.log('calculatorIsNeed, duration', calculatorIsNeed, duration)

  const eomTypeElem = docElem.createElement('EomType');
  eomTypeElem.appendChild(docElem.createTextNode(eomType));
  descriptionElem.appendChild(eomTypeElem);

  const moduleCodeElem = docElem.createElement('ModuleCode');
  moduleCodeElem.appendChild(docElem.createTextNode(moduleCode));
  descriptionElem.appendChild(moduleCodeElem);

  const textElem = docElem.createElement('Text');
  textElem.appendChild(docElem.createTextNode(escapeHtml(text)));
  descriptionElem.appendChild(textElem);

  const showStudentElem = docElem.createElement('ShowStudent');
  showStudentElem.appendChild(
    docElem.createTextNode(showStudent ? `${showStudent}` : `false`),
  );
  descriptionElem.appendChild(showStudentElem);

  const filesElem = docElem.createElement('Files');

  files.forEach((file) => {
    filesElem.appendChild(buildFile(docElem, file));
  });

  const tasksElem = docElem.createElement('Tasks');

  tasksFormArray?.forEach((taskFormGroup: any) => {
    tasksElem.appendChild(createTask(docElem, taskFormGroup));
    // console.log(formGroup)
  });

  rootElem.appendChild(durationElem);
  rootElem.appendChild(themeElem);
  rootElem.appendChild(instrumentsElem);
  rootElem.appendChild(descriptionElem);
  rootElem.appendChild(filesElem);
  rootElem.appendChild(tasksElem);
  docElem.appendChild(rootElem);
  // console.log(docElem)
  return docElem;
  // // Open XML new tab
  // let blob = new Blob([new XMLSerializer().serializeToString(docElem)], {type: "text/xml"});
  // let url = URL.createObjectURL(blob);
  // window.open(url, "_target");
  // URL.revokeObjectURL(url);
};

const buildFile = (docElem: XMLDocument, file: any) => {
  const fileElem = docElem.createElement('File');
  const fileIdElem = docElem.createElement('Id');
  const filePathElem = docElem.createElement('Path');

  fileIdElem.appendChild(docElem.createTextNode(file.id));
  filePathElem.appendChild(docElem.createTextNode(file.path));
  fileElem.appendChild(fileIdElem);
  fileElem.appendChild(filePathElem);
  return fileElem;
};

const createTask = (docElem: XMLDocument, taskFormGroup: any) => {
  switch (taskFormGroup.taskType) {
    case 'OneInput':
      return taskOneInput(docElem, taskFormGroup);
    case 'Checkbox':
      return taskCheckbox(docElem, taskFormGroup);
    case 'Relation':
      return taskRelation(docElem, taskFormGroup);
    case 'DragDrop':
      return taskDragDrop(docElem, taskFormGroup);
    case 'Dropdown':
    case 'DropDown':
      return taskDropDown(docElem, taskFormGroup);
    case 'MultiInput':
      return taskMultiInput(docElem, taskFormGroup);
    case 'MultiRichText':
      return taskMultiRichText(docElem, taskFormGroup);
    case 'Radio':
      return taskRadio(docElem, taskFormGroup);
    case 'RichText':
      return taskRichText(docElem, taskFormGroup);
    case 'SameDropdown':
      return taskSameDropdown(docElem, taskFormGroup);
    case 'MultiSelect':
      return taskMultiSelect(docElem, taskFormGroup);
    case 'MultiSelectTable':
      return taskMultiSelectTable(docElem, taskFormGroup);
    case 'Html5':
      return taskHtml5(docElem, taskFormGroup);
    case 'TimeLine':
      return taskTimeLine(docElem, taskFormGroup);
    default:
  }
};

function taskOneInput(docElem: XMLDocument, taskFormGroup: any) {
  const {
    taskType,
    id,
    number,
    mark,
    variantNumber,
    difficulty,
    question,
    text,
    rightAnswers,
    solution,
    showSolutionForTeacher,
    showSolutionForStudent,
    help,
    liner,
    isFormulaAnswer,
  } = taskFormGroup;
  // console.log('question', question);
  const taskElem = docElem.createElement(taskType);

  taskElem.appendChild(buildXmlElem(docElem, 'Id', id));
  taskElem.appendChild(buildXmlElem(docElem, 'Number', number));
  taskElem.appendChild(buildXmlElem(docElem, 'Mark', mark));
  taskElem.appendChild(buildXmlElem(docElem, 'VariantNumber', variantNumber));
  taskElem.appendChild(buildXmlElem(docElem, 'Difficulty', difficulty));
  taskElem.appendChild(buildXmlElem(docElem, 'Question', question));
  taskElem.appendChild(buildXmlElem(docElem, 'Text', escapeHtml(text)));
  taskElem.appendChild(buildXmlElem(docElem, 'Solution', escapeHtml(solution)));

  const rightAnswersElem = docElem.createElement('RightAnswers');

  // rightAnswers?.forEach(({ text }: any) => {
  //   rightAnswersElem.appendChild(
  //     buildXmlElem(docElem, 'Text', escapeHtml(text)),
  //   )
  // })
  rightAnswers?.forEach((text: any) => {
    rightAnswersElem.appendChild(
      buildXmlElem(docElem, 'Text', escapeHtml(text)),
    );
  });

  taskElem.appendChild(rightAnswersElem);

  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'IsFormulaAnswer',
      isFormulaAnswer ? `${isFormulaAnswer}` : `false`,
    ),
  );

  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ShowSolutionForTeacher',
      // showSolutionForTeacher ? `${showSolutionForTeacher}` : `false`,
      // made allways TRUE
      showSolutionForTeacher ? `${showSolutionForTeacher}` : `true`,
    ),
  );
  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ShowSolutionForStudent',
      // showSolutionForStudent ? `${showSolutionForStudent}` : `false`,
      // made allways TRUE
      showSolutionForStudent ? `${showSolutionForStudent}` : `true`,
    ),
  );
  taskElem.appendChild(buildXmlElem(docElem, 'Help', escapeHtml(help)));
  taskElem.appendChild(
    buildXmlElem(docElem, 'Liner', liner ? `${liner}` : `false`),
  );

  return taskElem;
}

function taskCheckbox(docElem: XMLDocument, taskFormGroup: any) {
  const {
    taskType,
    id,
    number,
    mark,
    variantNumber,
    difficulty,
    question,
    text,
    answers,
    solution,
    showSolutionForTeacher,
    showSolutionForStudent,
    help,
    liner,
    itemsHorizontally,
  } = taskFormGroup;
  const taskElem = docElem.createElement(taskType);
  taskElem.appendChild(buildXmlElem(docElem, 'Id', id));
  taskElem.appendChild(buildXmlElem(docElem, 'Number', number));
  taskElem.appendChild(buildXmlElem(docElem, 'Mark', mark));
  taskElem.appendChild(buildXmlElem(docElem, 'VariantNumber', variantNumber));
  taskElem.appendChild(buildXmlElem(docElem, 'Difficulty', difficulty));
  taskElem.appendChild(buildXmlElem(docElem, 'Question', question));
  taskElem.appendChild(buildXmlElem(docElem, 'Text', escapeHtml(text)));
  taskElem.appendChild(buildXmlElem(docElem, 'Solution', escapeHtml(solution)));
  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ItemsHorizontally',
      itemsHorizontally ? `${itemsHorizontally}` : `false`,
    ),
  );

  const rightAnswersElem = docElem.createElement('Answers');

  answers.forEach((answer: any) => {
    const elem = docElem.createElement('Answer');
    elem.appendChild(buildXmlElem(docElem, 'Text', escapeHtml(answer.text)));
    elem.appendChild(
      buildXmlElem(
        docElem,
        'IsKey',
        answer.isKey ? `${answer.isKey}` : `false`,
      ),
    );
    rightAnswersElem.appendChild(elem);
  });

  taskElem.appendChild(rightAnswersElem);

  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ShowSolutionForTeacher',
      showSolutionForTeacher ? `${showSolutionForTeacher}` : `false`,
    ),
  );
  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ShowSolutionForStudent',
      showSolutionForStudent ? `${showSolutionForStudent}` : `false`,
    ),
  );
  taskElem.appendChild(buildXmlElem(docElem, 'Help', escapeHtml(help)));
  taskElem.appendChild(
    buildXmlElem(docElem, 'Liner', liner ? `${liner}` : `false`),
  );

  return taskElem;
}

function taskRelation(docElem: XMLDocument, taskFormGroup: any) {
  const {
    taskType,
    id,
    number,
    mark,
    variantNumber,
    difficulty,
    question,
    text,
    // rightAnswers,
    primaryElements,
    secondaryElements,
    solution,
    showSolutionForTeacher,
    showSolutionForStudent,
    help,
    liner,
  } = taskFormGroup;

  // console.log('bothElements', bothElements)

  const taskElem = docElem.createElement(taskType);

  taskElem.appendChild(buildXmlElem(docElem, 'Id', id));
  taskElem.appendChild(buildXmlElem(docElem, 'Number', number));
  taskElem.appendChild(buildXmlElem(docElem, 'Mark', mark));
  taskElem.appendChild(buildXmlElem(docElem, 'VariantNumber', variantNumber));
  taskElem.appendChild(buildXmlElem(docElem, 'Difficulty', difficulty));
  taskElem.appendChild(buildXmlElem(docElem, 'Question', question));
  taskElem.appendChild(buildXmlElem(docElem, 'Text', escapeHtml(text)));
  taskElem.appendChild(buildXmlElem(docElem, 'Solution', escapeHtml(solution)));

  const secondaryElementsElem = docElem.createElement('Elements');
  taskElem.appendChild(secondaryElementsElem);

  let bothElements = [];
  if (primaryElements && secondaryElements) {
    bothElements = primaryElements.concat(secondaryElements);
  }
  bothElements.forEach((obj: any) => {
    const elem = docElem.createElement('Element');
    const type = obj.primaryId !== undefined ? 1 : 2;
    const id = obj.primaryId !== undefined ? obj.primaryId : obj.secondaryId;
    elem.appendChild(buildXmlElem(docElem, 'Id', escapeHtml(id)));
    elem.appendChild(
      buildXmlElem(docElem, 'Type', escapeHtml(type.toString())),
    );
    elem.appendChild(buildXmlElem(docElem, 'Content', escapeHtml(obj.content)));
    secondaryElementsElem.appendChild(elem);
  });

  const primaryElementsElem = docElem.createElement('Answers');
  primaryElements.forEach((obj: any) => {
    obj.answers.forEach((SecondaryId: any) => {
      const elem = docElem.createElement('Answer');
      elem.appendChild(
        buildXmlElem(docElem, 'PrimaryId', escapeHtml(obj.primaryId)),
      );
      elem.appendChild(
        buildXmlElem(docElem, 'SecondaryId', escapeHtml(SecondaryId)),
      );
      primaryElementsElem.appendChild(elem);
    });
  });

  taskElem.appendChild(primaryElementsElem);

  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ShowSolutionForTeacher',
      showSolutionForTeacher ? `${showSolutionForTeacher}` : `false`,
    ),
  );
  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ShowSolutionForStudent',
      showSolutionForStudent ? `${showSolutionForStudent}` : `false`,
    ),
  );
  taskElem.appendChild(buildXmlElem(docElem, 'Help', escapeHtml(help)));
  taskElem.appendChild(
    buildXmlElem(docElem, 'Liner', liner ? `${liner}` : `false`),
  );

  return taskElem;
}

function taskDragDrop(docElem: XMLDocument, taskFormGroup: any) {
  const {
    taskType,
    id,
    number,
    mark,
    variantNumber,
    difficulty,
    question,
    text,
    // dragElements,
    solution,
    showSolutionForTeacher,
    showSolutionForStudent,
    help,
    liner,
  } = taskFormGroup;

  const taskElem = docElem.createElement(taskType);

  taskElem.appendChild(buildXmlElem(docElem, 'Id', id));
  taskElem.appendChild(buildXmlElem(docElem, 'Number', number));
  taskElem.appendChild(buildXmlElem(docElem, 'Mark', mark));
  taskElem.appendChild(buildXmlElem(docElem, 'VariantNumber', variantNumber));
  taskElem.appendChild(buildXmlElem(docElem, 'Difficulty', difficulty));
  taskElem.appendChild(buildXmlElem(docElem, 'Question', question));
  taskElem.appendChild(buildXmlElem(docElem, 'Text', escapeHtml(text)));
  taskElem.appendChild(buildXmlElem(docElem, 'Solution', escapeHtml(solution)));

  const dragElements =
    taskFormGroup.dragElements === undefined ? [] : taskFormGroup.dragElements;
  const dropElements =
    taskFormGroup.dropElements === undefined ? [] : taskFormGroup.dropElements;
  let bothElements = [];
  if (dragElements && dropElements) {
    bothElements = dragElements.concat(dropElements);
  }

  const elementsElem = docElem.createElement('Elements');
  bothElements.forEach((obj: any) => {
    const elem = docElem.createElement('Element');
    const type = obj.dragId !== undefined ? 1 : 2;
    const id = obj.dragId !== undefined ? obj.dragId : obj.dropId;
    elem.appendChild(buildXmlElem(docElem, 'Id', escapeHtml(id)));
    elem.appendChild(
      buildXmlElem(docElem, 'Type', escapeHtml(type.toString())),
    );
    elem.appendChild(buildXmlElem(docElem, 'Content', escapeHtml(obj.content)));
    elementsElem.appendChild(elem);
  });

  const answersElem = docElem.createElement('Answers');
  dropElements.forEach((obj: any) => {
    obj.answers.forEach((answer: any) => {
      const elem = docElem.createElement('Answer');
      elem.appendChild(buildXmlElem(docElem, 'DragId', escapeHtml(answer)));
      elem.appendChild(buildXmlElem(docElem, 'DropId', escapeHtml(obj.dropId)));
      answersElem.appendChild(elem);
    });
  });

  taskElem.appendChild(elementsElem);
  taskElem.appendChild(answersElem);

  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ShowSolutionForTeacher',
      showSolutionForTeacher ? `${showSolutionForTeacher}` : `false`,
    ),
  );
  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ShowSolutionForStudent',
      showSolutionForStudent ? `${showSolutionForStudent}` : `false`,
    ),
  );
  taskElem.appendChild(buildXmlElem(docElem, 'Help', escapeHtml(help)));
  taskElem.appendChild(
    buildXmlElem(docElem, 'Liner', liner ? `${liner}` : `false`),
  );

  return taskElem;
}

function taskDropDown(docElem: XMLDocument, taskFormGroup: any) {
  const {
    taskType,
    id,
    number,
    mark,
    variantNumber,
    difficulty,
    question,
    text,
    lists,
    solution,
    showSolutionForTeacher,
    showSolutionForStudent,
    help,
    liner,
  } = taskFormGroup;

  const taskElem = docElem.createElement(taskType);

  taskElem.appendChild(buildXmlElem(docElem, 'Id', id));
  taskElem.appendChild(buildXmlElem(docElem, 'Number', number));
  taskElem.appendChild(buildXmlElem(docElem, 'Mark', mark));
  taskElem.appendChild(buildXmlElem(docElem, 'VariantNumber', variantNumber));
  taskElem.appendChild(buildXmlElem(docElem, 'Difficulty', difficulty));
  taskElem.appendChild(buildXmlElem(docElem, 'Question', question));
  taskElem.appendChild(buildXmlElem(docElem, 'Text', escapeHtml(text)));
  taskElem.appendChild(buildXmlElem(docElem, 'Solution', escapeHtml(solution)));

  const rightAnswersElem = docElem.createElement('Lists');

  lists.forEach((answer: any) => {
    const list = docElem.createElement('List');
    list.appendChild(buildXmlElem(docElem, 'Id', escapeHtml(answer.id)));
    const elements = docElem.createElement('Elements');

    answer.elements.forEach((answerElem: any) => {
      const element = docElem.createElement('Element');
      element.appendChild(
        buildXmlElem(docElem, 'Text', escapeHtml(answerElem.text)),
      );
      element.appendChild(
        buildXmlElem(
          docElem,
          'IsKey',
          answerElem.isKey ? `${answerElem.isKey}` : `false`,
        ),
      );
      elements.appendChild(element);
    });
    list.appendChild(elements);
    rightAnswersElem.appendChild(list);
  });

  taskElem.appendChild(rightAnswersElem);

  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ShowSolutionForTeacher',
      showSolutionForTeacher ? `${showSolutionForTeacher}` : `false`,
    ),
  );
  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ShowSolutionForStudent',
      showSolutionForStudent ? `${showSolutionForStudent}` : `false`,
    ),
  );
  taskElem.appendChild(buildXmlElem(docElem, 'Help', escapeHtml(help)));
  taskElem.appendChild(
    buildXmlElem(docElem, 'Liner', liner ? `${liner}` : `false`),
  );

  return taskElem;
}

function taskMultiInput(docElem: XMLDocument, taskFormGroup: any) {
  const {
    taskType,
    id,
    number,
    mark,
    variantNumber,
    difficulty,
    question,
    text,
    rightAnswers,
    solution,
    showSolutionForTeacher,
    showSolutionForStudent,
    help,
    liner,
    isFormulaAnswer,
  } = taskFormGroup;

  const taskElem = docElem.createElement(taskType);

  taskElem.appendChild(buildXmlElem(docElem, 'Id', id));
  taskElem.appendChild(buildXmlElem(docElem, 'Number', number));
  taskElem.appendChild(buildXmlElem(docElem, 'Mark', mark));
  taskElem.appendChild(buildXmlElem(docElem, 'VariantNumber', variantNumber));
  taskElem.appendChild(buildXmlElem(docElem, 'Difficulty', difficulty));
  taskElem.appendChild(buildXmlElem(docElem, 'Question', question));
  taskElem.appendChild(buildXmlElem(docElem, 'Text', escapeHtml(text)));
  taskElem.appendChild(buildXmlElem(docElem, 'Solution', escapeHtml(solution)));

  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'IsFormulaAnswer',
      isFormulaAnswer ? `${isFormulaAnswer}` : `false`,
    ),
  );

  const rightAnswersElem = docElem.createElement('RightAnswers');

  rightAnswers.forEach((answer: any) => {
    const elem = docElem.createElement('Answer');
    elem.appendChild(
      buildXmlElem(docElem, 'InputId', escapeHtml(answer.inputId)),
    );

    answer.texts.forEach((text: any) => {
      elem.appendChild(buildXmlElem(docElem, 'Text', escapeHtml(text)));
    });

    rightAnswersElem.appendChild(elem);
  });

  taskElem.appendChild(rightAnswersElem);

  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ShowSolutionForTeacher',
      showSolutionForTeacher ? `${showSolutionForTeacher}` : `false`,
    ),
  );
  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ShowSolutionForStudent',
      showSolutionForStudent ? `${showSolutionForStudent}` : `false`,
    ),
  );
  taskElem.appendChild(buildXmlElem(docElem, 'Help', escapeHtml(help)));
  taskElem.appendChild(
    buildXmlElem(docElem, 'Liner', liner ? `${liner}` : `false`),
  );

  return taskElem;
}

function taskMultiRichText(docElem: XMLDocument, taskFormGroup: any) {
  const {
    taskType,
    id,
    number,
    mark,
    variantNumber,
    difficulty,
    question,
    text,
    answerTypeBlank,
    criteria,
    solution,
    showSolutionForTeacher,
    showSolutionForStudent,
    help,
    liner,
    isFormulaAnswer,
  } = taskFormGroup;
  const taskElem = docElem.createElement(taskType);

  taskElem.appendChild(buildXmlElem(docElem, 'Id', id));
  taskElem.appendChild(buildXmlElem(docElem, 'Number', number));
  taskElem.appendChild(buildXmlElem(docElem, 'Mark', mark));
  taskElem.appendChild(buildXmlElem(docElem, 'VariantNumber', variantNumber));
  taskElem.appendChild(buildXmlElem(docElem, 'Difficulty', difficulty));
  taskElem.appendChild(buildXmlElem(docElem, 'Question', question));
  taskElem.appendChild(buildXmlElem(docElem, 'Text', escapeHtml(text)));
  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'AnswerTypeBlank',
      answerTypeBlank ? `${answerTypeBlank}` : `false`,
    ),
  );

  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'IsFormulaAnswer',
      isFormulaAnswer ? `${isFormulaAnswer}` : `false`,
    ),
  );

  taskElem.appendChild(buildXmlElem(docElem, 'Solution', escapeHtml(solution)));

  criteria.forEach((answer: any) => {
    const elem = docElem.createElement('Criteria');

    elem.appendChild(buildXmlElem(docElem, 'Id', escapeHtml(answer.id)));
    elem.appendChild(buildXmlElem(docElem, 'Mark', escapeHtml(answer.mark)));
    elem.appendChild(buildXmlElem(docElem, 'Text', escapeHtml(answer.text)));

    taskElem.appendChild(elem);
  });

  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ShowSolutionForTeacher',
      showSolutionForTeacher ? `${showSolutionForTeacher}` : `false`,
    ),
  );
  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ShowSolutionForStudent',
      showSolutionForStudent ? `${showSolutionForStudent}` : `false`,
    ),
  );
  taskElem.appendChild(buildXmlElem(docElem, 'Help', escapeHtml(help)));
  taskElem.appendChild(
    buildXmlElem(docElem, 'Liner', liner ? `${liner}` : `false`),
  );

  return taskElem;
}

function taskRadio(docElem: XMLDocument, taskFormGroup: any) {
  const {
    taskType,
    id,
    number,
    mark,
    variantNumber,
    difficulty,
    question,
    text,
    answers,
    solution,
    showSolutionForTeacher,
    showSolutionForStudent,
    help,
    liner,
    itemsHorizontally,
  } = taskFormGroup;

  const taskElem = docElem.createElement(taskType);

  taskElem.appendChild(buildXmlElem(docElem, 'Id', id));
  taskElem.appendChild(buildXmlElem(docElem, 'Number', number));
  taskElem.appendChild(buildXmlElem(docElem, 'Mark', mark));
  taskElem.appendChild(buildXmlElem(docElem, 'VariantNumber', variantNumber));
  taskElem.appendChild(buildXmlElem(docElem, 'Difficulty', difficulty));
  taskElem.appendChild(buildXmlElem(docElem, 'Question', question));
  taskElem.appendChild(buildXmlElem(docElem, 'Text', escapeHtml(text)));
  taskElem.appendChild(buildXmlElem(docElem, 'Solution', escapeHtml(solution)));
  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ItemsHorizontally',
      itemsHorizontally ? `${itemsHorizontally}` : `false`,
    ),
  );

  const rightAnswersElem = docElem.createElement('Answers');
  answers.forEach((answer: any) => {
    const elem = docElem.createElement('Answer');
    elem.appendChild(buildXmlElem(docElem, 'Text', escapeHtml(answer.text)));
    elem.appendChild(
      buildXmlElem(
        docElem,
        'IsKey',
        answer.isKey ? `${answer.isKey}` : `false`,
      ),
    );
    rightAnswersElem.appendChild(elem);
  });

  taskElem.appendChild(rightAnswersElem);

  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ShowSolutionForTeacher',
      showSolutionForTeacher ? `${showSolutionForTeacher}` : `false`,
    ),
  );
  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ShowSolutionForStudent',
      showSolutionForStudent ? `${showSolutionForStudent}` : `false`,
    ),
  );
  taskElem.appendChild(buildXmlElem(docElem, 'Help', escapeHtml(help)));
  taskElem.appendChild(
    buildXmlElem(docElem, 'Liner', liner ? `${liner}` : `false`),
  );

  return taskElem;
}

function taskRichText(docElem: XMLDocument, taskFormGroup: any) {
  const {
    taskType,
    id,
    number,
    mark,
    variantNumber,
    difficulty,
    question,
    text,
    answerTypeBlank,
    answerTypePhoto,
    answerTypeKeyboard,
    criteria,
    solution,
    showSolutionForTeacher,
    showSolutionForStudent,
    help,
    liner,
    isFormulaAnswer,
  } = taskFormGroup;

  const taskElem = docElem.createElement(taskType);

  taskElem.appendChild(buildXmlElem(docElem, 'Id', id));
  taskElem.appendChild(buildXmlElem(docElem, 'Number', number));
  taskElem.appendChild(buildXmlElem(docElem, 'Mark', mark));
  taskElem.appendChild(buildXmlElem(docElem, 'VariantNumber', variantNumber));
  taskElem.appendChild(buildXmlElem(docElem, 'Difficulty', difficulty));
  taskElem.appendChild(buildXmlElem(docElem, 'Question', question));
  taskElem.appendChild(buildXmlElem(docElem, 'Text', escapeHtml(text)));
  taskElem.appendChild(buildXmlElem(docElem, 'Solution', escapeHtml(solution)));
  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'AnswerTypeBlank',
      answerTypeBlank ? `${answerTypeBlank}` : `false`,
    ),
  );
  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'AnswerTypePhoto',
      answerTypePhoto ? `${answerTypePhoto}` : `false`,
    ),
  );
  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'AnswerTypeKeyboard',
      answerTypeKeyboard ? `${answerTypeKeyboard}` : `false`,
    ),
  );

  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'IsFormulaAnswer',
      isFormulaAnswer ? `${isFormulaAnswer}` : `false`,
    ),
  );

  criteria.forEach((answer: any) => {
    const elem = docElem.createElement('Criteria');

    elem.appendChild(buildXmlElem(docElem, 'Id', escapeHtml(answer.id)));
    elem.appendChild(buildXmlElem(docElem, 'Mark', escapeHtml(answer.mark)));
    elem.appendChild(buildXmlElem(docElem, 'Text', escapeHtml(answer.text)));

    taskElem.appendChild(elem);
  });

  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ShowSolutionForTeacher',
      showSolutionForTeacher ? `${showSolutionForTeacher}` : `false`,
    ),
  );
  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ShowSolutionForStudent',
      showSolutionForStudent ? `${showSolutionForStudent}` : `false`,
    ),
  );
  taskElem.appendChild(buildXmlElem(docElem, 'Help', escapeHtml(help)));
  taskElem.appendChild(
    buildXmlElem(docElem, 'Liner', liner ? `${liner}` : `false`),
  );

  return taskElem;
}

function taskSameDropdown(docElem: XMLDocument, taskFormGroup: any) {
  const {
    taskType,
    id,
    number,
    mark,
    variantNumber,
    difficulty,
    question,
    text,
    lists,
    solution,
    showSolutionForTeacher,
    showSolutionForStudent,
    help,
    liner,
  } = taskFormGroup;

  const taskElem = docElem.createElement(taskType);

  taskElem.appendChild(buildXmlElem(docElem, 'Id', id));
  taskElem.appendChild(buildXmlElem(docElem, 'Number', number));
  taskElem.appendChild(buildXmlElem(docElem, 'Mark', mark));
  taskElem.appendChild(buildXmlElem(docElem, 'VariantNumber', variantNumber));
  taskElem.appendChild(buildXmlElem(docElem, 'Difficulty', difficulty));
  taskElem.appendChild(buildXmlElem(docElem, 'Question', question));
  taskElem.appendChild(buildXmlElem(docElem, 'Text', escapeHtml(text)));
  taskElem.appendChild(buildXmlElem(docElem, 'Solution', escapeHtml(solution)));

  const rightAnswersElem = docElem.createElement('Lists');

  lists.forEach((answer: any) => {
    const list = docElem.createElement('List');

    list.appendChild(buildXmlElem(docElem, 'Id', escapeHtml(answer.id)));

    answer.elements.forEach((answerElem: any) => {
      const element = docElem.createElement('Element');
      element.appendChild(
        buildXmlElem(docElem, 'Text', escapeHtml(answerElem.text)),
      );
      element.appendChild(
        buildXmlElem(
          docElem,
          'IsKey',
          answerElem.isKey ? `${answerElem.isKey}` : `false`,
        ),
      );
      list.appendChild(element);
    });

    rightAnswersElem.appendChild(list);
  });

  taskElem.appendChild(rightAnswersElem);

  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ShowSolutionForTeacher',
      showSolutionForTeacher ? `${showSolutionForTeacher}` : `false`,
    ),
  );
  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ShowSolutionForStudent',
      showSolutionForStudent ? `${showSolutionForStudent}` : `false`,
    ),
  );
  taskElem.appendChild(buildXmlElem(docElem, 'Help', escapeHtml(help)));
  taskElem.appendChild(
    buildXmlElem(docElem, 'Liner', liner ? `${liner}` : `false`),
  );

  return taskElem;
}

function taskMultiSelect(docElem: XMLDocument, taskFormGroup: any) {
  const {
    taskType,
    id,
    number,
    mark,
    variantNumber,
    difficulty,
    question,
    text,
    answers,
    solution,
    showSolutionForTeacher,
    showSolutionForStudent,
    help,
    liner,
  } = taskFormGroup;

  const taskElem = docElem.createElement(taskType);

  taskElem.appendChild(buildXmlElem(docElem, 'Id', id));
  taskElem.appendChild(buildXmlElem(docElem, 'Number', number));
  taskElem.appendChild(buildXmlElem(docElem, 'Mark', mark));
  taskElem.appendChild(buildXmlElem(docElem, 'VariantNumber', variantNumber));
  taskElem.appendChild(buildXmlElem(docElem, 'Difficulty', difficulty));
  taskElem.appendChild(buildXmlElem(docElem, 'Question', question));
  taskElem.appendChild(buildXmlElem(docElem, 'Text', escapeHtml(text)));
  taskElem.appendChild(buildXmlElem(docElem, 'Solution', escapeHtml(solution)));

  const rightAnswersElem = docElem.createElement('Answers');

  answers.forEach((answer: any) => {
    const elem = docElem.createElement('Answer');
    elem.appendChild(buildXmlElem(docElem, 'Id', escapeHtml(answer.id)));
    elem.appendChild(buildXmlElem(docElem, 'Text', escapeHtml(answer.text)));
    elem.appendChild(
      buildXmlElem(
        docElem,
        'IsKey',
        answer.isKey ? `${answer.isKey}` : `false`,
      ),
    );
    rightAnswersElem.appendChild(elem);
  });

  taskElem.appendChild(rightAnswersElem);

  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ShowSolutionForTeacher',
      showSolutionForTeacher ? `${showSolutionForTeacher}` : `false`,
    ),
  );
  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ShowSolutionForStudent',
      showSolutionForStudent ? `${showSolutionForStudent}` : `false`,
    ),
  );
  taskElem.appendChild(buildXmlElem(docElem, 'Help', escapeHtml(help)));
  taskElem.appendChild(
    buildXmlElem(docElem, 'Liner', liner ? `${liner}` : `false`),
  );

  return taskElem;
}

function taskMultiSelectTable(docElem: XMLDocument, taskFormGroup: any) {
  const {
    taskType,
    id,
    number,
    mark,
    variantNumber,
    difficulty,
    question,
    text,
    answers,
    table,
    solution,
    showSolutionForTeacher,
    showSolutionForStudent,
    help,
    liner,
  } = taskFormGroup;

  const taskElem = docElem.createElement(taskType);

  taskElem.appendChild(buildXmlElem(docElem, 'Id', id));
  taskElem.appendChild(buildXmlElem(docElem, 'Number', number));
  taskElem.appendChild(buildXmlElem(docElem, 'Mark', mark));
  taskElem.appendChild(buildXmlElem(docElem, 'VariantNumber', variantNumber));
  taskElem.appendChild(buildXmlElem(docElem, 'Difficulty', difficulty));
  taskElem.appendChild(buildXmlElem(docElem, 'Question', question));
  taskElem.appendChild(buildXmlElem(docElem, 'Text', escapeHtml(text)));
  taskElem.appendChild(buildXmlElem(docElem, 'Solution', escapeHtml(solution)));

  const rightAnswersElem = docElem.createElement('Answers');

  answers.forEach((answer: any) => {
    const elem = docElem.createElement('Answer');
    elem.appendChild(buildXmlElem(docElem, 'Id', escapeHtml(answer.id)));
    elem.appendChild(buildXmlElem(docElem, 'Text', escapeHtml(answer.text)));
    elem.appendChild(
      buildXmlElem(
        docElem,
        'IsKey',
        answer.isKey ? `${answer.isKey}` : `false`,
      ),
    );
    rightAnswersElem.appendChild(elem);
  });

  taskElem.appendChild(rightAnswersElem);

  taskElem.appendChild(buildXmlElem(docElem, 'Table', escapeHtml(table)));

  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ShowSolutionForTeacher',
      showSolutionForTeacher ? `${showSolutionForTeacher}` : `false`,
    ),
  );
  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ShowSolutionForStudent',
      showSolutionForStudent ? `${showSolutionForStudent}` : `false`,
    ),
  );
  taskElem.appendChild(buildXmlElem(docElem, 'Help', escapeHtml(help)));
  taskElem.appendChild(
    buildXmlElem(docElem, 'Liner', liner ? `${liner}` : `false`),
  );

  return taskElem;
}

function taskHtml5(docElem: XMLDocument, taskFormGroup: any) {
  const {
    taskType,
    id,
    number,
    mark,
    variantNumber,
    difficulty,
    solution,
    showSolutionForTeacher,
    showSolutionForStudent,
    help,
    liner,
  } = taskFormGroup;

  const taskElem = docElem.createElement(taskType);

  taskElem.appendChild(buildXmlElem(docElem, 'Id', id));
  taskElem.appendChild(buildXmlElem(docElem, 'Number', number));
  taskElem.appendChild(buildXmlElem(docElem, 'Mark', mark));
  taskElem.appendChild(buildXmlElem(docElem, 'VariantNumber', variantNumber));
  taskElem.appendChild(buildXmlElem(docElem, 'Difficulty', difficulty));

  taskElem.appendChild(buildXmlElem(docElem, 'Solution', escapeHtml(solution)));

  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ShowSolutionForTeacher',
      showSolutionForTeacher ? `${showSolutionForTeacher}` : `false`,
    ),
  );
  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ShowSolutionForStudent',
      showSolutionForStudent ? `${showSolutionForStudent}` : `false`,
    ),
  );
  taskElem.appendChild(buildXmlElem(docElem, 'Help', escapeHtml(help)));
  taskElem.appendChild(
    buildXmlElem(docElem, 'Liner', liner ? `${liner}` : `false`),
  );

  return taskElem;
}

function taskTimeLine(docElem: XMLDocument, taskFormGroup: any) {
  const {
    taskType,
    id,
    number,
    mark,
    variantNumber,
    difficulty,
    question,
    text,
    dragElements,
    groups,
    solution,
    showSolutionForTeacher,
    showSolutionForStudent,
    help,
    liner,
  } = taskFormGroup;
  // console.log('Timeline: ', taskFormGroup);
  const taskElem = docElem.createElement(taskType);

  taskElem.appendChild(buildXmlElem(docElem, 'Id', id));
  taskElem.appendChild(buildXmlElem(docElem, 'Number', number));
  taskElem.appendChild(buildXmlElem(docElem, 'Mark', mark));
  taskElem.appendChild(buildXmlElem(docElem, 'VariantNumber', variantNumber));
  taskElem.appendChild(buildXmlElem(docElem, 'Difficulty', difficulty));
  taskElem.appendChild(buildXmlElem(docElem, 'Question', question));
  taskElem.appendChild(buildXmlElem(docElem, 'Text', text));

  const groupsElem = docElem.createElement('Groups');
  groups.forEach((obj: any) => {
    const elem = docElem.createElement('Group');
    elem.appendChild(buildXmlElem(docElem, 'Name', escapeHtml(obj.name)));
    const subgroupsElem = docElem.createElement('Subgroups');
    elem.appendChild(subgroupsElem);
    obj.subgroups.forEach((subgroup: any) => {
      const subgroupElem = docElem.createElement('Subgroup');
      subgroupElem.appendChild(
        buildXmlElem(docElem, 'DropId', escapeHtml(subgroup.dropId)),
      );
      subgroupElem.appendChild(
        buildXmlElem(docElem, 'Name', escapeHtml(subgroup.name)),
      );
      subgroupsElem.appendChild(subgroupElem);
    });
    groupsElem.appendChild(elem);
  });
  taskElem.appendChild(groupsElem);

  const elementsElem = docElem.createElement('Elements');
  let bothElements = [];
  let elemId;
  if (dragElements && groups) {
    bothElements = dragElements.concat(groups);
  }
  bothElements.forEach((obj: any) => {
    // console.log('123', obj)
    const elem = docElem.createElement('Element');
    const type = obj.dragId !== undefined ? 1 : 2;
    // for
    if (obj.subgroups?.length > 0) {
      obj.subgroups.forEach((subgroup: any) => {
        elemId = subgroup.dropId;
        elem.appendChild(buildXmlElem(docElem, 'Id', escapeHtml(elemId)));
        elem.appendChild(
          buildXmlElem(docElem, 'Type', escapeHtml(type.toString())),
        );
        elementsElem.appendChild(elem);
      });
    } else {
      elemId = obj.dragId;
      elem.appendChild(buildXmlElem(docElem, 'Id', escapeHtml(elemId)));
      elem.appendChild(
        buildXmlElem(docElem, 'Type', escapeHtml(type.toString())),
      );
      elem.appendChild(
        buildXmlElem(docElem, 'Subject', escapeHtml(obj.subject)),
      );
      elem.appendChild(
        buildXmlElem(
          docElem,
          'SubjectAdditional',
          escapeHtml(obj.subjectAdditional),
        ),
      );
      elem.appendChild(
        buildXmlElem(docElem, 'Content', escapeHtml(obj.content)),
      );
      elementsElem.appendChild(elem);
    }
  });
  taskElem.appendChild(elementsElem);

  const answersElem = docElem.createElement('Answers');
  groups.forEach((obj: any) => {
    obj.subgroups.forEach((subgroup: any) => {
      subgroup.answers.forEach((answer: any) => {
        const elem = docElem.createElement('Answer');
        elem.appendChild(
          buildXmlElem(docElem, 'DropId', escapeHtml(subgroup.dropId)),
        );
        elem.appendChild(buildXmlElem(docElem, 'DragId', escapeHtml(answer)));
        answersElem.appendChild(elem);
      });
    });
  });
  taskElem.appendChild(answersElem);

  taskElem.appendChild(buildXmlElem(docElem, 'Solution', escapeHtml(solution)));
  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ShowSolutionForTeacher',
      showSolutionForTeacher ? `${showSolutionForTeacher}` : `false`,
    ),
  );
  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ShowSolutionForStudent',
      showSolutionForStudent ? `${showSolutionForStudent}` : `false`,
    ),
  );
  taskElem.appendChild(buildXmlElem(docElem, 'Help', escapeHtml(help)));
  taskElem.appendChild(
    buildXmlElem(docElem, 'Liner', liner ? `${liner}` : `false`),
  );

  return taskElem;
}

function buildXmlElem(docElem: any, name: string, value: string) {
  // console.log(name)
  const elem = docElem.createElement(name);
  elem.appendChild(docElem.createTextNode(value));
  return elem;
}
