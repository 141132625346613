export const FORM_DATA_REQUEST = 'FORM_DATA_REQUEST'
export const FORM_DATA_SUCCESS = 'FORM_DATA_SUCCESS'
export const FORM_DATA_FAILURE = 'FORM_DATA_FAILURE'

export const FORM_EDIT_REQUEST = 'FORM_EDIT_REQUEST'
export const FORM_EDIT_SUCCESS = 'FORM_EDIT_SUCCESS'
export const FORM_EDIT_FAILURE = 'FORM_EDIT_FAILURE'

export const FORM_CREATE_REQUEST = 'FORM_CREATE_REQUEST'
export const FORM_CREATE_SUCCESS = 'FORM_CREATE_SUCCESS'
export const FORM_RESET = 'FORM_RESET'

export const FORM_INITIALIZE = 'FORM_INITIALIZE'
export const FORM_CHANGE = 'FORM_CHANGE'
