import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import App from 'app'
import { history, persistor, store } from 'app/Store'
import { ConnectedRouter } from 'connected-react-router/immutable'
import { PersistGate } from 'redux-persist/integration/react'
import './app/sentry'
import ErrorBoundary from 'components/Errors/Boundary'

import 'styles/index.css'

// import './perf-tools-keeper'

// import reportWebVitals from './reportWebVitals'
// import * as serviceWorkerRegistration from './serviceWorkerRegistration'

// global variable for local load ckeditor4 files
// @ts-ignore
// window.CKEDITOR_BASEPATH = '/ckeditor/'

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <Router history={history}>
            <App />
          </Router>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  </ErrorBoundary>,
  document.querySelector('#root'),
)

// reportWebVitals()
// serviceWorkerRegistration.register()
