import { notification } from 'antd'

interface INotification {
  type: 'success' | 'info' | 'warning' | 'error'
  message: string
  description?: string
  placement?: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight'
}

const openNotification = ({
  type,
  message,
  description,
  placement,
}: INotification) => {
  notification[type]({
    message,
    description,
    placement,
  })
}

export default openNotification
