import { connectRouter } from 'connected-react-router'
import { History, LocationState } from 'history'
import storage from 'localforage'
import { AnyAction, combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { persistReducer } from 'redux-persist'
import immutableTransform from 'redux-persist-transform-immutable'

// Components
import flowLoaderReducer from 'components/Flows/Loader/reducer'
import modalsReducer from 'components/Flows/Modal/reducer'
import graphFormReducer from 'components/Graphs/Form/reducer'
import graphTableReducer from 'components/Graphs/Table/reducer'
import optionsReducer from 'components/Inputs/SelectField/redux/reducer'
import valueSetReducer from 'components/Inputs/ValueSet/reducer'
import { RESET_STORE } from 'components/StateManagement/constants'

type TPersistConfig = {
  transforms: any
  key: string
  whitelist: string[]
  storage: any
}

const rootPersistConfig: TPersistConfig = {
  transforms: [immutableTransform()],
  key: 'apkpro',
  whitelist: [],
  storage,
}

export const appReducer = (history: History<LocationState>) =>
  persistReducer(
    rootPersistConfig,
    combineReducers({
      router: connectRouter(history),
      // Redux-form
      form: formReducer,
      // Components
      graphForm: graphFormReducer,
      graphTable: graphTableReducer,
      valueSets: valueSetReducer,
      loader: flowLoaderReducer,
      options: optionsReducer,
      modals: modalsReducer,
      // Modules
    }),
  )

export const rootReducer = (reducer: any) => (
  state: any,
  action: AnyAction,
) => {
  if (action.type === RESET_STORE) {
    storage.removeItem('persist:apkpro').then(() => {
      state = undefined
    })
  }
  return reducer(state, action)
}
