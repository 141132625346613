import { lazy } from 'react';

const ConstructorForm = lazy(() => import('./pages/formPage'));
const ConstructorTable = lazy(() => import('./pages/tablePage'));
const authPage = lazy(() => import('./pages/authPage'));
const blocksPage = lazy(() => import('./pages/blocksPage'));
const interactivePage = lazy(() => import('./pages/interactivePage'));
const ConstructorTableHistoryPage = lazy(
  () => import('./pages/tableHistoryPage'),
);

export default [
  {
    exact: true,
    path: '/',
    component: ConstructorTable,
  },
  {
    exact: true,
    path: '/auth',
    component: authPage,
  },
  {
    exact: true,
    path: '/constructor',
    component: ConstructorTable,
  },
  {
    exact: true,
    path: '/constructor/:type',
    component: ConstructorForm,
  },
  {
    exact: true,
    path: '/constructor/:type/:id',
    component: ConstructorForm,
  },
  {
    exact: true,
    path: '/constructor/:type/:id/history',
    component: ConstructorTableHistoryPage,
  },
  {
    exact: true,
    path: '/constructor/:type/:id/history/:historyId',
    component: ConstructorForm,
  },
  {
    exact: true,
    path: '/blocks',
    component: blocksPage,
  },
  {
    exact: true,
    path: '/interactive',
    component: interactivePage,
  },
];
