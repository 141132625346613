import { fromJS } from 'immutable'
import { AnyAction } from 'redux'

import { CLOSE_MODAL, OPEN_MODAL } from './constants'

const initialState = fromJS({})

const reducer = (state = initialState, { type, ...payload }: AnyAction) => {
  const id = payload && payload.id
  const props = payload && payload.props

  switch (type) {
    case OPEN_MODAL: {
      return state.setIn([id, 'open'], true).setIn([id, 'props'], props)
    }

    case CLOSE_MODAL: {
      return state.setIn([id, 'open'], false)
    }

    default:
      return state
  }
}

export default reducer
