import React, { FC } from 'react'

import { ILoading } from './types'

import BasicLoading from './basic'
import IconsLoading from './icon'

const Loading: FC<ILoading> = ({ type, size, spin }) => {
  switch (type) {
    case 'icon':
      return <IconsLoading size={size} spin={spin} />
    default:
      return <BasicLoading size={size} spin={spin} />
  }
}

export default Loading
