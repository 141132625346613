import React, { FC, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Link } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { IRouterProps } from 'types';

import Icons from 'components/Loading';

import { makeSelectToken } from '../selectors';

const PrivateLayout = lazy(() => import('app/Layouts/private'));

const Routes: FC<IRouterProps> = ({ path, component: Component }) => (
  <Suspense fallback={<Icons type="default" size={24} spin={true} />}>
    <PrivateLayout>
      <Route
        path={path}
        render={() => {
          if (path === '/') {
            // return <Redirect to="/auth" />
            return <Redirect to="/constructor" />;
          }
          return <Component />;
        }}
      />
    </PrivateLayout>
  </Suspense>
);
const mapStateToProps = createStructuredSelector({
  token: makeSelectToken(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(Routes);
