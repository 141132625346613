export const GET_ALL_EOMS_REQUEST = 'GET_ALL_EOMS_REQUEST';
export const CREATE_EOM_REQUEST = 'CREATE_EOM_REQUEST';
export const EDIT_EOM_REQUEST = 'EDIT_EOM_REQUEST';
export const DELETE_EOM_REQUEST = 'DELETE_EOM_REQUEST';
export const GET_EOM_REQUEST = 'GET_EOM_REQUEST';
export const GET_EOM_SUCCESS = 'GET_EOM_SUCCESS';
export const ADD_TASK_REQUEST = 'ADD_TASK_REQUEST';
export const GET_EOM_TASK_REQUEST = 'GET_EOM_TASK_REQUEST';
export const EDIT_EOM_TASK_REQUEST = 'EDIT_EOM_TASK_REQUEST';
export const GENERATE_XML_REQUEST = 'GENERATE_XML_REQUEST';
export const DELETE_EOMS_REQUEST = 'DELETE_EOMS_REQUEST';
export const ADD_ANSWER_TO_TASK_REQUEST = 'ADD_ANSWER_TO_TASK_REQUEST';
export const GET_ANSWER_FROM_TASK_REQUEST = 'GET_ANSWER_FROM_TASK_REQUEST';

export const ADD_ANSWER_TO_TASK_SELECT_REQUEST =
  'ADD_ANSWER_TO_TASK_SELECT_REQUEST';
export const GET_ANSWER_FROM_TASK_SELECT_REQUEST =
  'GET_ANSWER_FROM_TASK_SELECT_REQUEST';
export const EDIT_ANSWER_FROM_TASK_SELECT_REQUEST =
  'EDIT_ANSWER_FROM_TASK_SELECT_REQUEST';

// TODO
export const ADD_ANSWER_TO_TASK_SELECT_REQUEST_SECOND =
  'ADD_ANSWER_TO_TASK_SELECT_REQUEST_SECOND';
export const GET_ANSWER_FROM_TASK_SELECT_REQUEST_SECOND =
  'GET_ANSWER_FROM_TASK_SELECT_REQUEST_SECOND';
export const EDIT_ANSWER_FROM_TASK_SELECT_REQUEST_SECOND =
  'EDIT_ANSWER_FROM_TASK_SELECT_REQUEST_SECOND';

export const GENERATE_XML_ZIP_REQUEST = 'GENERATE_XML_ZIP_REQUEST';
export const EDIT_ANSWER_FROM_TASK_REQUEST = 'EDIT_ANSWER_FROM_TASK_REQUEST';
export const GENERATE_XML_SERVER_REQUEST = 'GENERATE_XML_SERVER_REQUEST';
export const GENERATE_CHECKING_XML_REQUEST = 'GENERATE_CHECKING_XML_REQUEST';
export const GET_EOMS_HISTORY_REQUEST = 'GET_EOMS_HISTORY_REQUEST';
export const ADD_EOM_HISTORY_REQUEST = 'ADD_EOM_HISTORY_REQUEST';
export const IMPORT_FROM_BPM_REQUEST = 'IMPORT_FROM_BPM_REQUEST';
export const GET_STATUS_CHECKING_REQUEST = 'GET_STATUS_CHECKING_REQUEST';
