import { all, fork } from 'redux-saga/effects'

import errorSaga from 'components/Errors/saga'
import graphFormSaga from 'components/Graphs/Form/saga'
import graphTableSaga from 'components/Graphs/Table/saga'
import UploadSaga from 'components/Inputs/Upload/saga'
import usersSaga from 'modules/Constructor/redux/saga'

function* rootSaga() {
  yield all([
    // Components
    fork(graphFormSaga),
    fork(graphTableSaga),
    fork(errorSaga),
    fork(UploadSaga),

    // Modules
    fork(usersSaga),
  ])
}

export default rootSaga
