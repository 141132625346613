import createAction from 'utils/createAction';

import {
  ADD_ANSWER_TO_TASK_REQUEST,
  ADD_ANSWER_TO_TASK_SELECT_REQUEST,
  //
  ADD_TASK_REQUEST,
  CREATE_EOM_REQUEST,
  DELETE_EOM_REQUEST,
  DELETE_EOMS_REQUEST,
  EDIT_ANSWER_FROM_TASK_REQUEST,
  EDIT_ANSWER_FROM_TASK_SELECT_REQUEST,
  EDIT_EOM_REQUEST,
  EDIT_EOM_TASK_REQUEST,
  GENERATE_CHECKING_XML_REQUEST,
  GENERATE_XML_REQUEST,
  GENERATE_XML_SERVER_REQUEST,
  GENERATE_XML_ZIP_REQUEST,
  GET_ALL_EOMS_REQUEST,
  GET_ANSWER_FROM_TASK_REQUEST,
  GET_ANSWER_FROM_TASK_SELECT_REQUEST,
  GET_EOM_REQUEST,
  GET_EOM_SUCCESS,
  GET_EOM_TASK_REQUEST,
  GET_EOMS_HISTORY_REQUEST,
  ADD_EOM_HISTORY_REQUEST,
  ADD_ANSWER_TO_TASK_SELECT_REQUEST_SECOND,
  GET_ANSWER_FROM_TASK_SELECT_REQUEST_SECOND,
  EDIT_ANSWER_FROM_TASK_SELECT_REQUEST_SECOND,
  IMPORT_FROM_BPM_REQUEST,
  GET_STATUS_CHECKING_REQUEST,
} from './constants';

export const getEOMRequest = createAction(GET_EOM_REQUEST);
export const getAllEOMSRequest = createAction(GET_ALL_EOMS_REQUEST);
export const getEOMSHistoryRequest = createAction(GET_EOMS_HISTORY_REQUEST);
export const addEOMHistoryRequest = createAction(ADD_EOM_HISTORY_REQUEST);
export const getEOMSuccess = createAction(GET_EOM_SUCCESS);
export const editEOMRequest = createAction(EDIT_EOM_REQUEST);
export const createEOMRequest = createAction(CREATE_EOM_REQUEST);
export const deleteEOMRequest = createAction(DELETE_EOM_REQUEST);
export const deleteEOMsRequest = createAction(DELETE_EOMS_REQUEST);

// FOMS
export const addTaskRequest = createAction(ADD_TASK_REQUEST);
export const getEomTaskRequest = createAction(GET_EOM_TASK_REQUEST);
export const editEomTaskRequest = createAction(EDIT_EOM_TASK_REQUEST);

export const addAnswerToTaskRequest = createAction(ADD_ANSWER_TO_TASK_REQUEST);
export const getAnswerFromTaskRequest = createAction(
  GET_ANSWER_FROM_TASK_REQUEST,
);
export const editAnswerFromTaskRequest = createAction(
  EDIT_ANSWER_FROM_TASK_REQUEST,
);

//
export const addAnswerToTaskSelectRequest = createAction(
  ADD_ANSWER_TO_TASK_SELECT_REQUEST,
);
export const getAnswerFromTaskSelectRequest = createAction(
  GET_ANSWER_FROM_TASK_SELECT_REQUEST,
);
export const editAnswerFromTaskSelectRequest = createAction(
  EDIT_ANSWER_FROM_TASK_SELECT_REQUEST,
);

//
export const addAnswerToTaskSelectRequestSecond = createAction(
  ADD_ANSWER_TO_TASK_SELECT_REQUEST_SECOND,
);
export const getAnswerFromTaskSelectRequestSecond = createAction(
  GET_ANSWER_FROM_TASK_SELECT_REQUEST_SECOND,
);
export const editAnswerFromTaskSelectRequestSecond = createAction(
  EDIT_ANSWER_FROM_TASK_SELECT_REQUEST_SECOND,
);

// XML
export const generateXMLRequest = createAction(GENERATE_XML_REQUEST);
export const generateXML_ZIP_Request = createAction(GENERATE_XML_ZIP_REQUEST);
export const generateXML_Server_Request = createAction(
  GENERATE_XML_SERVER_REQUEST,
);
export const generateCheckingXML_Request = createAction(
  GENERATE_CHECKING_XML_REQUEST,
);

export const getStatusChecking_Request = createAction(
  GET_STATUS_CHECKING_REQUEST,
);

export const importFromBpm_Request = createAction(IMPORT_FROM_BPM_REQUEST);
