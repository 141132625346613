import { AnyAction } from 'redux'
import { all, put, takeEvery } from 'redux-saga/effects'

import { tableDataSuccess } from './actions'
import { TABLE_DATA_REQUEST } from './constants'

function* get({ meta: { table, init, options = {} } }: AnyAction) {
  const { page = 0, size = 10, ...args } = options
  yield put(
    init({
      meta: {
        table,
        callback: tableDataSuccess,
        page,
        size,
        ...args,
      },
    }),
  )
}

function* Saga() {
  yield all([takeEvery(TABLE_DATA_REQUEST, get)])
}

export default Saga
