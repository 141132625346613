import moment from 'moment'

import { IFlowData } from 'components/Flows/Form/types'

export const getObjectTrim = (object: IFlowData | string) => {
  if (typeof object !== 'object' || object === null) {
    return object
  }
  return Object.keys(object).reduce(
    (memo, key) => {
      const typeKey = typeof object[key]
      let value = object[key]
      switch (typeKey) {
        case 'string':
          value = object[key].trim()
          break
        case 'object':
          value = getObjectTrim(object[key])
          break
        default:
          value = object[key]
      }

      if (Array.isArray(memo)) {
        return [...memo, value]
      }

      return { ...memo, [key]: value }
    },
    Array.isArray(object) ? [] : {},
  )
}

export const normalizeArrayToMetaTable = (array: any) => {
  const totalSize = Array.isArray(array) ? array.length : 0
  return { entities: array, page: 0, size: 1000, totalSize }
}

export const orderNormalize = (antOrder: string) => {
  switch (antOrder) {
    case 'descend':
      return 'DESC'
    case 'ascend':
      return 'ASC'
    default:
      return antOrder
  }
}

export const addonBefore = (item: any) =>
  item?.addonBefore
    ? {
        addonBefore: {
          [item.id]: item.addonBefore.defaultValue,
        },
      }
    : {}

export const formatDate = (
  date: string,
  format = 'DD.MM.YYYY HH:mm',
  utc = false,
) => {
  if (utc) {
    return moment.utc(date).local().format(format)
  }
  return moment(date).format(format)
}

const clearHtml = (str: string) => {
  if (!str) {
    str = ''
  }

  return str
    .replace(/<br\s*[\/]?>/gi, '')
    .replaceAll('&nbsp;', '')
    .replaceAll('<p></p>', '')
}

export const escapeHtml = (str: string) => {
  if (!str) {
    str = ''
  }

  str = clearHtml(str)

  return (
    str
      // replace(/&/g, "&amp;").
      // .replace('<p></p>', '')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;')
  )
}
