import { IActionProps } from 'types'

import {
  FORM_CHANGE,
  FORM_CREATE_REQUEST,
  FORM_CREATE_SUCCESS,
  FORM_DATA_REQUEST,
  FORM_DATA_SUCCESS,
  FORM_EDIT_REQUEST,
  FORM_EDIT_SUCCESS,
  FORM_RESET,
} from './constants'

export const formDataRequest = ({ payload, meta }: IActionProps) => ({
  type: FORM_DATA_REQUEST,
  payload,
  meta,
})

export const formDataSuccess = ({ payload, meta }: IActionProps) => ({
  type: FORM_DATA_SUCCESS,
  payload,
  meta,
})

export const formEditRequest = ({ payload, meta }: IActionProps) => ({
  type: FORM_EDIT_REQUEST,
  payload,
  meta,
})

export const formEditSuccess = ({ payload, meta }: IActionProps) => ({
  type: FORM_EDIT_SUCCESS,
  payload,
  meta,
})

export const formCreateRequest = ({ payload, meta }: IActionProps) => ({
  type: FORM_CREATE_REQUEST,
  payload,
  meta,
})

export const formCreateSuccess = ({ payload, meta }: IActionProps) => ({
  type: FORM_CREATE_SUCCESS,
  payload,
  meta,
})

export const formReset = ({ payload, meta }: IActionProps) => ({
  type: FORM_RESET,
  payload,
  meta,
})

export const formChange = ({ payload, meta }: IActionProps) => ({
  type: FORM_CHANGE,
  payload,
  meta,
})
