import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

const env = process.env.REACT_APP_ENV
const dsn = 'https://045707ae2eff44aa8508a789e59ee158@sentry.apkpro.ru/12'

Sentry.init({
  dsn,
  environment: env,
  integrations: [new Integrations.Dedupe()], // https://docs.sentry.io/platforms/javascript/#dedupe
  beforeSend: (event) => {
    if (window.location.hostname === 'localhost') {
      return null
    }
    return event
  },
})
