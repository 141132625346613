import { IUploadStatus } from '../types';
import { request } from 'api/restClient';
import { Services } from 'constants/services';

class ClassAPI {
  uploadFile = (
    file: Blob,
    eomId: string,
    formData: { fileType: string; name: string },
  ) => {
    const bodyFormData = new FormData();
    bodyFormData.append('file', file);
    bodyFormData.append('fileType', formData.fileType);
    bodyFormData.append('name', formData.name);
    // console.log(file, eomId, bodyFormData);
    return request({
      service: Services.SINGLE_AUTH,
      url: `diagnostic-eom/${eomId}/files/upload/`,
      method: 'POST',
      data: bodyFormData,
    });
  };

  removeFile = (fileId: Blob, eomId: string) => {
    return request({
      service: Services.SINGLE_AUTH,
      url: `diagnostic-eom/${eomId}/files/${fileId}/`,
      method: 'DELETE',
    });
  };

  removeAllFiles = (eomId: string) => {
    return request({
      service: Services.SINGLE_AUTH,
      url: `diagnostic-eom/${eomId}/files/`,
      method: 'DELETE',
    });
  };

  getUploadStatus = (params: IUploadStatus) =>
    request({
      service: Services.SINGLE_AUTH,
      url: `v1/cdn/upload/status`,
      method: 'GET',
      params,
    });
}

const USER_API = new ClassAPI();

export default USER_API;
