import { ICreateUser, IEditUser, IRequestUsers, IHistoryEom } from '../types';
import { request } from 'api/restClient';
import { Services } from 'constants/services';

class ClassAPI {
  getEOM = (eomId: string | number) => {
    return request({
      service: Services.SINGLE_AUTH,
      url: `diagnostic-eom/${eomId}/`,
      method: 'GET',
    });
  };

  getEOMHistory = (eomId: string | number, historyId: string | number) => {
    return request({
      service: Services.SINGLE_AUTH,
      url: `diagnostic-eom/${eomId}/history/${historyId}/`,
      method: 'GET',
    });
  };

  addEOMHistory = ({ id }: any) => {
    return request({
      service: Services.SINGLE_AUTH,
      url: `diagnostic-eom/${id}/history/`,
      method: 'POST',
      data: {},
    });
  };

  getEOMFiles = (eomId: string | number) =>
    request({
      service: Services.SINGLE_AUTH,
      url: `diagnostic-eom/${eomId}/files/`,
      method: 'GET',
    });

  editEom = (graphId: string | number, data: IEditUser) =>
    request({
      service: Services.SINGLE_AUTH,
      url: `diagnostic-eom/${graphId}/`,
      method: 'PUT',
      data,
    });

  createEom = (data: ICreateUser) =>
    request({
      service: Services.SINGLE_AUTH,
      url: 'diagnostic-eom/new/',
      method: 'POST',
      data,
    });

  deleteEom = (id: number) =>
    request({
      service: Services.SINGLE_AUTH,
      url: `diagnostic-eom/${id}/`,
      method: 'DELETE',
    });

  deleteEoms = (fields: string[]) => {
    const requests = fields.map((id) =>
      request({
        service: Services.SINGLE_AUTH,
        url: `diagnostic-eom/${id}/`,
        method: 'DELETE',
      }),
    );

    return Promise.all(requests).then((responses) => {
      return responses;
    });
  };

  getAllEOMs = ({ page, size, q }: IRequestUsers) => {
    return request({
      service: Services.SINGLE_AUTH,
      url: `diagnostic-eom/`,
      method: 'GET',
      params: {
        page,
        page_size: size,
        q,
      },
    });
  };

  getEOMSHistory = ({ id, page, size }: IHistoryEom) => {
    return request({
      service: Services.SINGLE_AUTH,
      // url: `diagnostic-eom/${id}/history/?sort=created&order=desc&page=1`,
      url: `diagnostic-eom/${id}/history/`,
      method: 'GET',
      params: {
        page,
        page_size: size,
      },
    });
  };

  generateZip = async ({ id, xml }: any) => {
    return request({
      service: Services.SINGLE_AUTH,
      url: `diagnostic-eom/${id}/zip/`,
      method: 'GET',
      data: { 'diagnostic-eom-scheme': xml },
    });
  };

  generateZipServer = async ({ id }: any) => {
    return request({
      service: Services.SINGLE_AUTH,
      url: `diagnostic-eom/${id}/zip/`,
      method: 'GET',
    });
  };

  generateChecking = async ({ id, uploadBpm, uploadBk }: any) => {
    return request({
      service: Services.SINGLE_AUTH,
      url: `export-ts/`,
      method: 'POST',
      data: {
        id,
        uploadBk,
        uploadBpm,
      },
    });
  };

  generateExportTsZip = async ({ id }: any) => {
    return request({
      service: Services.SINGLE_AUTH,
      url: `export-ts/zip/`,
      method: 'POST',
      data: {
        id: id,
        uploadBk: false,
        uploadBpm: false,
      },
    });
  };

  getStatusChecking = async ({ id }: any) => {
    return request({
      service: Services.SINGLE_AUTH,
      url: `export-ts/`,
      method: 'GET',
      params: {
        eom_id: id,
      },
    });
  };

  getStatusImportFromBpm = async ({ codeEom }: any) => {
    return request({
      service: Services.SINGLE_AUTH,
      url: `diagnostic-eom/import-from-bpm/`,
      method: 'POST',
      data: {
        code: codeEom,
      },
    });
  };
}

const USER_API = new ClassAPI();

export default USER_API;
