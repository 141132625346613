import { escapeHtml } from './formatters';
export const buildXmlSchema = (
  descriptionFormGroup: any,
  tasksFormArray: any,
  files: any[],
) => {
  const docElem = document.implementation.createDocument('', '', null);
  const pi = docElem.createProcessingInstruction(
    'xml',
    'version="1.0" encoding="UTF-8"',
  );
  docElem.insertBefore(pi, docElem.firstChild);

  const rootElem = docElem.createElement('t:Eom');
  rootElem.setAttribute('xmlns:t', 'msh.tasktypes');
  rootElem.setAttribute(
    'xmlns:xsi',
    'http://www.w3.org/2001/XMLSchema-instance',
  );

  const descriptionElem = docElem.createElement('Description');

  const { eomType, moduleCode, text, showStudent } = descriptionFormGroup;

  const eomTypeElem = docElem.createElement('EomType');
  eomTypeElem.appendChild(docElem.createTextNode(eomType));
  descriptionElem.appendChild(eomTypeElem);

  const moduleCodeElem = docElem.createElement('ModuleCode');
  moduleCodeElem.appendChild(docElem.createTextNode(moduleCode));
  descriptionElem.appendChild(moduleCodeElem);

  const textElem = docElem.createElement('Text');
  textElem.appendChild(docElem.createTextNode(escapeHtml(text)));
  descriptionElem.appendChild(textElem);

  const showStudentElem = docElem.createElement('ShowStudent');
  showStudentElem.appendChild(
    docElem.createTextNode(showStudent ? `${showStudent}` : `false`),
  );
  descriptionElem.appendChild(showStudentElem);

  const filesElem = docElem.createElement('Files');

  files.forEach((file) => {
    filesElem.appendChild(buildFile(docElem, file));
  });

  const tasksElem = docElem.createElement('Tasks');

  tasksFormArray?.forEach((taskFormGroup: any) => {
    tasksElem.appendChild(createTask(docElem, taskFormGroup));
    // console.log(formGroup)
  });

  rootElem.appendChild(descriptionElem);
  rootElem.appendChild(filesElem);
  rootElem.appendChild(tasksElem);
  docElem.appendChild(rootElem);
  // console.log(docElem)
  return docElem;
  // // Open XML new tab
  // let blob = new Blob([new XMLSerializer().serializeToString(docElem)], {type: "text/xml"});
  // let url = URL.createObjectURL(blob);
  // window.open(url, "_target");
  // URL.revokeObjectURL(url);
};

const buildFile = (docElem: XMLDocument, file: any) => {
  const fileElem = docElem.createElement('File');
  const fileIdElem = docElem.createElement('Id');
  const filePathElem = docElem.createElement('Path');

  fileIdElem.appendChild(docElem.createTextNode(file.id));
  filePathElem.appendChild(docElem.createTextNode(file.path));
  fileElem.appendChild(fileIdElem);
  fileElem.appendChild(filePathElem);
  return fileElem;
};

const createTask = (docElem: XMLDocument, taskFormGroup: any) => {
  switch (taskFormGroup.taskType) {
    case 'OneInput':
      return taskOneInput(docElem, taskFormGroup);
    case 'Checkbox':
      return taskCheckbox(docElem, taskFormGroup);
    case 'Relation':
      return taskRelation(docElem, taskFormGroup);
    case 'DragDrop':
      return taskDragDrop(docElem, taskFormGroup);
    case 'Dropdown':
    case 'DropDown':
      return taskDropDown(docElem, taskFormGroup);
    case 'MultiInput':
      return taskMultiInput(docElem, taskFormGroup);
    case 'MultiRichText':
      return taskMultiRichText(docElem, taskFormGroup);
    case 'Radio':
      return taskRadio(docElem, taskFormGroup);
    case 'RichText':
      return taskRichText(docElem, taskFormGroup);
    case 'SameDropdown':
      return taskSameDropdown(docElem, taskFormGroup);

    case 'MultiSelect':
      return taskMultiSelect(docElem, taskFormGroup);
    case 'MultiSelectTable':
      return taskMultiSelectTable(docElem, taskFormGroup);
    case 'Html5':
      return taskHtml5(docElem, taskFormGroup);
    case 'TimeLine':
      return taskTimeLine(docElem, taskFormGroup);
    default:
  }
};

function taskOneInput(docElem: XMLDocument, taskFormGroup: any) {
  const {
    taskType,
    id,
    number,
    mark,
    variantNumber,
    difficulty,
    text,
    rightAnswers,
    solution,
    showSolutionForTeacher,
    showSolutionForStudent,
    help,
    liner,
  } = taskFormGroup;
  const taskElem = docElem.createElement(taskType);

  taskElem.appendChild(buildXmlElem(docElem, 'Id', id));
  taskElem.appendChild(buildXmlElem(docElem, 'Number', number));
  taskElem.appendChild(buildXmlElem(docElem, 'Mark', mark));
  taskElem.appendChild(buildXmlElem(docElem, 'VariantNumber', variantNumber));
  taskElem.appendChild(buildXmlElem(docElem, 'Difficulty', difficulty));
  taskElem.appendChild(buildXmlElem(docElem, 'Text', escapeHtml(text)));
  taskElem.appendChild(buildXmlElem(docElem, 'Solution', escapeHtml(solution)));

  const rightAnswersElem = docElem.createElement('RightAnswers');

  // rightAnswers?.forEach(({ text }: any) => {
  //   rightAnswersElem.appendChild(
  //     buildXmlElem(docElem, 'Text', escapeHtml(text)),
  //   )
  // })
  rightAnswers?.forEach((text: any) => {
    rightAnswersElem.appendChild(
      buildXmlElem(docElem, 'Text', escapeHtml(text)),
    );
  });

  taskElem.appendChild(rightAnswersElem);

  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ShowSolutionForTeacher',
      // showSolutionForTeacher ? `${showSolutionForTeacher}` : `false`,
      // made allways TRUE
      showSolutionForTeacher ? `${showSolutionForTeacher}` : `true`,
    ),
  );
  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ShowSolutionForStudent',
      // showSolutionForStudent ? `${showSolutionForStudent}` : `false`,
      // made allways TRUE
      showSolutionForStudent ? `${showSolutionForStudent}` : `true`,
    ),
  );
  taskElem.appendChild(buildXmlElem(docElem, 'Help', escapeHtml(help)));
  taskElem.appendChild(
    buildXmlElem(docElem, 'Liner', liner ? `${liner}` : `false`),
  );

  return taskElem;
}

function taskCheckbox(docElem: XMLDocument, taskFormGroup: any) {
  const {
    taskType,
    id,
    number,
    mark,
    variantNumber,
    difficulty,
    text,
    answers,
    solution,
    showSolutionForTeacher,
    showSolutionForStudent,
    help,
    liner,
  } = taskFormGroup;

  const taskElem = docElem.createElement(taskType);

  taskElem.appendChild(buildXmlElem(docElem, 'Id', id));
  taskElem.appendChild(buildXmlElem(docElem, 'Number', number));
  taskElem.appendChild(buildXmlElem(docElem, 'Mark', mark));
  taskElem.appendChild(buildXmlElem(docElem, 'VariantNumber', variantNumber));
  taskElem.appendChild(buildXmlElem(docElem, 'Difficulty', difficulty));
  taskElem.appendChild(buildXmlElem(docElem, 'Text', escapeHtml(text)));
  taskElem.appendChild(buildXmlElem(docElem, 'Solution', escapeHtml(solution)));

  const rightAnswersElem = docElem.createElement('Answers');

  answers.forEach((answer: any) => {
    const elem = docElem.createElement('Answer');
    elem.appendChild(buildXmlElem(docElem, 'Text', escapeHtml(answer.text)));
    elem.appendChild(
      buildXmlElem(
        docElem,
        'IsKey',
        answer.isKey ? `${answer.isKey}` : `false`,
      ),
    );
    rightAnswersElem.appendChild(elem);
  });

  taskElem.appendChild(rightAnswersElem);

  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ShowSolutionForTeacher',
      showSolutionForTeacher ? `${showSolutionForTeacher}` : `false`,
    ),
  );
  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ShowSolutionForStudent',
      showSolutionForStudent ? `${showSolutionForStudent}` : `false`,
    ),
  );
  taskElem.appendChild(buildXmlElem(docElem, 'Help', escapeHtml(help)));
  taskElem.appendChild(
    buildXmlElem(docElem, 'Liner', liner ? `${liner}` : `false`),
  );

  return taskElem;
}

function taskRelation(docElem: XMLDocument, taskFormGroup: any) {
  const {
    taskType,
    id,
    number,
    mark,
    variantNumber,
    difficulty,
    text,
    rightAnswers,
    solution,
    showSolutionForTeacher,
    showSolutionForStudent,
    help,
    liner,
  } = taskFormGroup;

  const taskElem = docElem.createElement(taskType);

  taskElem.appendChild(buildXmlElem(docElem, 'Id', id));
  taskElem.appendChild(buildXmlElem(docElem, 'Number', number));
  taskElem.appendChild(buildXmlElem(docElem, 'Mark', mark));
  taskElem.appendChild(buildXmlElem(docElem, 'VariantNumber', variantNumber));
  taskElem.appendChild(buildXmlElem(docElem, 'Difficulty', difficulty));
  taskElem.appendChild(buildXmlElem(docElem, 'Text', escapeHtml(text)));
  taskElem.appendChild(buildXmlElem(docElem, 'Solution', escapeHtml(solution)));

  const rightAnswersElem = docElem.createElement('RightAnswers');

  rightAnswers.forEach((answer: any) => {
    const elem = docElem.createElement('Answer');
    elem.appendChild(
      buildXmlElem(docElem, 'Primary', escapeHtml(answer.primary)),
    );
    elem.appendChild(
      buildXmlElem(docElem, 'Secondary', escapeHtml(answer.secondary)),
    );
    rightAnswersElem.appendChild(elem);
  });

  taskElem.appendChild(rightAnswersElem);

  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ShowSolutionForTeacher',
      showSolutionForTeacher ? `${showSolutionForTeacher}` : `false`,
    ),
  );
  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ShowSolutionForStudent',
      showSolutionForStudent ? `${showSolutionForStudent}` : `false`,
    ),
  );
  taskElem.appendChild(buildXmlElem(docElem, 'Help', escapeHtml(help)));
  taskElem.appendChild(
    buildXmlElem(docElem, 'Liner', liner ? `${liner}` : `false`),
  );

  return taskElem;
}

function taskDragDrop(docElem: XMLDocument, taskFormGroup: any) {
  const {
    taskType,
    id,
    number,
    mark,
    variantNumber,
    difficulty,
    text,
    // elements,
    solution,
    showSolutionForTeacher,
    showSolutionForStudent,
    help,
    liner,
  } = taskFormGroup;

  const elements =
    taskFormGroup.elements === undefined ? [] : taskFormGroup.elements;

  // console.log('taskFormGroup', taskFormGroup) // --> TypeError: elements is undefined
  const taskElem = docElem.createElement(taskType);

  taskElem.appendChild(buildXmlElem(docElem, 'Id', id));
  taskElem.appendChild(buildXmlElem(docElem, 'Number', number));
  taskElem.appendChild(buildXmlElem(docElem, 'Mark', mark));
  taskElem.appendChild(buildXmlElem(docElem, 'VariantNumber', variantNumber));
  taskElem.appendChild(buildXmlElem(docElem, 'Difficulty', difficulty));
  taskElem.appendChild(buildXmlElem(docElem, 'Text', escapeHtml(text)));
  taskElem.appendChild(buildXmlElem(docElem, 'Solution', escapeHtml(solution)));

  const rightAnswersElem = docElem.createElement('Elements');

  elements.forEach((answer: any) => {
    const elem = docElem.createElement('Element');
    elem.appendChild(
      buildXmlElem(docElem, 'DropId', escapeHtml(answer.dropId)),
    );
    elem.appendChild(buildXmlElem(docElem, 'Text', escapeHtml(answer.text)));
    rightAnswersElem.appendChild(elem);
  });

  taskElem.appendChild(rightAnswersElem);

  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ShowSolutionForTeacher',
      showSolutionForTeacher ? `${showSolutionForTeacher}` : `false`,
    ),
  );
  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ShowSolutionForStudent',
      showSolutionForStudent ? `${showSolutionForStudent}` : `false`,
    ),
  );
  taskElem.appendChild(buildXmlElem(docElem, 'Help', escapeHtml(help)));
  taskElem.appendChild(
    buildXmlElem(docElem, 'Liner', liner ? `${liner}` : `false`),
  );

  return taskElem;
}

function taskDropDown(docElem: XMLDocument, taskFormGroup: any) {
  const {
    taskType,
    id,
    number,
    mark,
    variantNumber,
    difficulty,
    text,
    lists,
    solution,
    showSolutionForTeacher,
    showSolutionForStudent,
    help,
    liner,
  } = taskFormGroup;

  const taskElem = docElem.createElement(taskType);

  taskElem.appendChild(buildXmlElem(docElem, 'Id', id));
  taskElem.appendChild(buildXmlElem(docElem, 'Number', number));
  taskElem.appendChild(buildXmlElem(docElem, 'Mark', mark));
  taskElem.appendChild(buildXmlElem(docElem, 'VariantNumber', variantNumber));
  taskElem.appendChild(buildXmlElem(docElem, 'Difficulty', difficulty));
  taskElem.appendChild(buildXmlElem(docElem, 'Text', escapeHtml(text)));
  taskElem.appendChild(buildXmlElem(docElem, 'Solution', escapeHtml(solution)));

  const rightAnswersElem = docElem.createElement('Lists');

  lists.forEach((answer: any) => {
    const list = docElem.createElement('List');
    list.appendChild(buildXmlElem(docElem, 'Id', escapeHtml(answer.id)));
    const elements = docElem.createElement('Elements');

    answer.elements.forEach((answerElem: any) => {
      const element = docElem.createElement('Element');
      element.appendChild(
        buildXmlElem(docElem, 'Text', escapeHtml(answerElem.text)),
      );
      element.appendChild(
        buildXmlElem(
          docElem,
          'IsKey',
          answerElem.isKey ? `${answerElem.isKey}` : `false`,
        ),
      );
      elements.appendChild(element);
    });
    list.appendChild(elements);
    rightAnswersElem.appendChild(list);
  });

  taskElem.appendChild(rightAnswersElem);

  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ShowSolutionForTeacher',
      showSolutionForTeacher ? `${showSolutionForTeacher}` : `false`,
    ),
  );
  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ShowSolutionForStudent',
      showSolutionForStudent ? `${showSolutionForStudent}` : `false`,
    ),
  );
  taskElem.appendChild(buildXmlElem(docElem, 'Help', escapeHtml(help)));
  taskElem.appendChild(
    buildXmlElem(docElem, 'Liner', liner ? `${liner}` : `false`),
  );

  return taskElem;
}

function taskMultiInput(docElem: XMLDocument, taskFormGroup: any) {
  const {
    taskType,
    id,
    number,
    mark,
    variantNumber,
    difficulty,
    text,
    rightAnswers,
    solution,
    showSolutionForTeacher,
    showSolutionForStudent,
    help,
    liner,
  } = taskFormGroup;

  const taskElem = docElem.createElement(taskType);

  taskElem.appendChild(buildXmlElem(docElem, 'Id', id));
  taskElem.appendChild(buildXmlElem(docElem, 'Number', number));
  taskElem.appendChild(buildXmlElem(docElem, 'Mark', mark));
  taskElem.appendChild(buildXmlElem(docElem, 'VariantNumber', variantNumber));
  taskElem.appendChild(buildXmlElem(docElem, 'Difficulty', difficulty));
  taskElem.appendChild(buildXmlElem(docElem, 'Text', escapeHtml(text)));
  taskElem.appendChild(buildXmlElem(docElem, 'Solution', escapeHtml(solution)));

  const rightAnswersElem = docElem.createElement('RightAnswers');

  rightAnswers.forEach((answer: any) => {
    const elem = docElem.createElement('Answer');
    elem.appendChild(
      buildXmlElem(docElem, 'InputId', escapeHtml(answer.inputId)),
    );

    answer.texts.forEach((text: any) => {
      elem.appendChild(buildXmlElem(docElem, 'Text', escapeHtml(text)));
    });

    rightAnswersElem.appendChild(elem);
  });

  taskElem.appendChild(rightAnswersElem);

  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ShowSolutionForTeacher',
      showSolutionForTeacher ? `${showSolutionForTeacher}` : `false`,
    ),
  );
  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ShowSolutionForStudent',
      showSolutionForStudent ? `${showSolutionForStudent}` : `false`,
    ),
  );
  taskElem.appendChild(buildXmlElem(docElem, 'Help', escapeHtml(help)));
  taskElem.appendChild(
    buildXmlElem(docElem, 'Liner', liner ? `${liner}` : `false`),
  );

  return taskElem;
}

function taskMultiRichText(docElem: XMLDocument, taskFormGroup: any) {
  const {
    taskType,
    id,
    number,
    mark,
    variantNumber,
    difficulty,
    text,
    criteria,
    solution,
    showSolutionForTeacher,
    showSolutionForStudent,
    help,
    liner,
  } = taskFormGroup;

  const taskElem = docElem.createElement(taskType);

  taskElem.appendChild(buildXmlElem(docElem, 'Id', id));
  taskElem.appendChild(buildXmlElem(docElem, 'Number', number));
  taskElem.appendChild(buildXmlElem(docElem, 'Mark', mark));
  taskElem.appendChild(buildXmlElem(docElem, 'VariantNumber', variantNumber));
  taskElem.appendChild(buildXmlElem(docElem, 'Difficulty', difficulty));
  taskElem.appendChild(buildXmlElem(docElem, 'Text', escapeHtml(text)));
  taskElem.appendChild(buildXmlElem(docElem, 'Solution', escapeHtml(solution)));

  criteria.forEach((answer: any) => {
    const elem = docElem.createElement('Criteria');

    elem.appendChild(buildXmlElem(docElem, 'Id', escapeHtml(answer.id)));
    elem.appendChild(buildXmlElem(docElem, 'Mark', escapeHtml(answer.mark)));
    elem.appendChild(buildXmlElem(docElem, 'Text', escapeHtml(answer.text)));

    taskElem.appendChild(elem);
  });

  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ShowSolutionForTeacher',
      showSolutionForTeacher ? `${showSolutionForTeacher}` : `false`,
    ),
  );
  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ShowSolutionForStudent',
      showSolutionForStudent ? `${showSolutionForStudent}` : `false`,
    ),
  );
  taskElem.appendChild(buildXmlElem(docElem, 'Help', escapeHtml(help)));
  taskElem.appendChild(
    buildXmlElem(docElem, 'Liner', liner ? `${liner}` : `false`),
  );

  return taskElem;
}

function taskRadio(docElem: XMLDocument, taskFormGroup: any) {
  const {
    taskType,
    id,
    number,
    mark,
    variantNumber,
    difficulty,
    text,
    answers,
    solution,
    showSolutionForTeacher,
    showSolutionForStudent,
    help,
    liner,
  } = taskFormGroup;

  const taskElem = docElem.createElement(taskType);

  taskElem.appendChild(buildXmlElem(docElem, 'Id', id));
  taskElem.appendChild(buildXmlElem(docElem, 'Number', number));
  taskElem.appendChild(buildXmlElem(docElem, 'Mark', mark));
  taskElem.appendChild(buildXmlElem(docElem, 'VariantNumber', variantNumber));
  taskElem.appendChild(buildXmlElem(docElem, 'Difficulty', difficulty));
  taskElem.appendChild(buildXmlElem(docElem, 'Text', escapeHtml(text)));
  taskElem.appendChild(buildXmlElem(docElem, 'Solution', escapeHtml(solution)));

  const rightAnswersElem = docElem.createElement('Answers');

  answers.forEach((answer: any) => {
    const elem = docElem.createElement('Answer');
    elem.appendChild(buildXmlElem(docElem, 'Text', escapeHtml(answer.text)));
    elem.appendChild(
      buildXmlElem(
        docElem,
        'IsKey',
        answer.isKey ? `${answer.isKey}` : `false`,
      ),
    );
    rightAnswersElem.appendChild(elem);
  });

  taskElem.appendChild(rightAnswersElem);

  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ShowSolutionForTeacher',
      showSolutionForTeacher ? `${showSolutionForTeacher}` : `false`,
    ),
  );
  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ShowSolutionForStudent',
      showSolutionForStudent ? `${showSolutionForStudent}` : `false`,
    ),
  );
  taskElem.appendChild(buildXmlElem(docElem, 'Help', escapeHtml(help)));
  taskElem.appendChild(
    buildXmlElem(docElem, 'Liner', liner ? `${liner}` : `false`),
  );

  return taskElem;
}

function taskRichText(docElem: XMLDocument, taskFormGroup: any) {
  const {
    taskType,
    id,
    number,
    mark,
    variantNumber,
    difficulty,
    text,
    answerTypePhoto,
    answerTypeKeyboard,
    criteria,
    solution,
    showSolutionForTeacher,
    showSolutionForStudent,
    help,
    liner,
  } = taskFormGroup;

  const taskElem = docElem.createElement(taskType);

  taskElem.appendChild(buildXmlElem(docElem, 'Id', id));
  taskElem.appendChild(buildXmlElem(docElem, 'Number', number));
  taskElem.appendChild(buildXmlElem(docElem, 'Mark', mark));
  taskElem.appendChild(buildXmlElem(docElem, 'VariantNumber', variantNumber));
  taskElem.appendChild(buildXmlElem(docElem, 'Difficulty', difficulty));
  taskElem.appendChild(buildXmlElem(docElem, 'Text', escapeHtml(text)));
  taskElem.appendChild(buildXmlElem(docElem, 'Solution', escapeHtml(solution)));

  taskElem.appendChild(
    buildXmlElem(docElem, 'AnswerTypePhoto', answerTypePhoto),
  );
  taskElem.appendChild(
    buildXmlElem(docElem, 'AnswerTypeKeyboard', answerTypeKeyboard),
  );

  criteria.forEach((answer: any) => {
    const elem = docElem.createElement('Criteria');

    elem.appendChild(buildXmlElem(docElem, 'Id', escapeHtml(answer.id)));
    elem.appendChild(buildXmlElem(docElem, 'Mark', escapeHtml(answer.mark)));
    elem.appendChild(buildXmlElem(docElem, 'Text', escapeHtml(answer.text)));

    taskElem.appendChild(elem);
  });

  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ShowSolutionForTeacher',
      showSolutionForTeacher ? `${showSolutionForTeacher}` : `false`,
    ),
  );
  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ShowSolutionForStudent',
      showSolutionForStudent ? `${showSolutionForStudent}` : `false`,
    ),
  );
  taskElem.appendChild(buildXmlElem(docElem, 'Help', escapeHtml(help)));
  taskElem.appendChild(
    buildXmlElem(docElem, 'Liner', liner ? `${liner}` : `false`),
  );

  return taskElem;
}

function taskSameDropdown(docElem: XMLDocument, taskFormGroup: any) {
  const {
    taskType,
    id,
    number,
    mark,
    variantNumber,
    difficulty,
    text,
    lists,
    solution,
    showSolutionForTeacher,
    showSolutionForStudent,
    help,
    liner,
  } = taskFormGroup;

  const taskElem = docElem.createElement(taskType);

  taskElem.appendChild(buildXmlElem(docElem, 'Id', id));
  taskElem.appendChild(buildXmlElem(docElem, 'Number', number));
  taskElem.appendChild(buildXmlElem(docElem, 'Mark', mark));
  taskElem.appendChild(buildXmlElem(docElem, 'VariantNumber', variantNumber));
  taskElem.appendChild(buildXmlElem(docElem, 'Difficulty', difficulty));
  taskElem.appendChild(buildXmlElem(docElem, 'Text', escapeHtml(text)));
  taskElem.appendChild(buildXmlElem(docElem, 'Solution', escapeHtml(solution)));

  const rightAnswersElem = docElem.createElement('Lists');

  lists.forEach((answer: any) => {
    const list = docElem.createElement('List');

    list.appendChild(buildXmlElem(docElem, 'Id', escapeHtml(answer.id)));

    answer.elements.forEach((answerElem: any) => {
      const element = docElem.createElement('Element');
      element.appendChild(
        buildXmlElem(docElem, 'Text', escapeHtml(answerElem.text)),
      );
      element.appendChild(
        buildXmlElem(
          docElem,
          'IsKey',
          answerElem.isKey ? `${answerElem.isKey}` : `false`,
        ),
      );
      list.appendChild(element);
    });

    rightAnswersElem.appendChild(list);
  });

  taskElem.appendChild(rightAnswersElem);

  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ShowSolutionForTeacher',
      showSolutionForTeacher ? `${showSolutionForTeacher}` : `false`,
    ),
  );
  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ShowSolutionForStudent',
      showSolutionForStudent ? `${showSolutionForStudent}` : `false`,
    ),
  );
  taskElem.appendChild(buildXmlElem(docElem, 'Help', escapeHtml(help)));
  taskElem.appendChild(
    buildXmlElem(docElem, 'Liner', liner ? `${liner}` : `false`),
  );

  return taskElem;
}

function taskMultiSelect(docElem: XMLDocument, taskFormGroup: any) {
  const {
    taskType,
    id,
    number,
    mark,
    variantNumber,
    difficulty,
    text,
    answers,
    solution,
    showSolutionForTeacher,
    showSolutionForStudent,
    help,
    liner,
  } = taskFormGroup;

  const taskElem = docElem.createElement(taskType);

  taskElem.appendChild(buildXmlElem(docElem, 'Id', id));
  taskElem.appendChild(buildXmlElem(docElem, 'Number', number));
  taskElem.appendChild(buildXmlElem(docElem, 'Mark', mark));
  taskElem.appendChild(buildXmlElem(docElem, 'VariantNumber', variantNumber));
  taskElem.appendChild(buildXmlElem(docElem, 'Difficulty', difficulty));
  taskElem.appendChild(buildXmlElem(docElem, 'Text', escapeHtml(text)));
  taskElem.appendChild(buildXmlElem(docElem, 'Solution', escapeHtml(solution)));

  const rightAnswersElem = docElem.createElement('Answers');

  answers.forEach((answer: any) => {
    const elem = docElem.createElement('Answer');
    elem.appendChild(buildXmlElem(docElem, 'Id', escapeHtml(answer.id)));
    elem.appendChild(buildXmlElem(docElem, 'Text', escapeHtml(answer.text)));
    elem.appendChild(
      buildXmlElem(
        docElem,
        'IsKey',
        answer.isKey ? `${answer.isKey}` : `false`,
      ),
    );
    rightAnswersElem.appendChild(elem);
  });

  taskElem.appendChild(rightAnswersElem);

  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ShowSolutionForTeacher',
      showSolutionForTeacher ? `${showSolutionForTeacher}` : `false`,
    ),
  );
  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ShowSolutionForStudent',
      showSolutionForStudent ? `${showSolutionForStudent}` : `false`,
    ),
  );
  taskElem.appendChild(buildXmlElem(docElem, 'Help', escapeHtml(help)));
  taskElem.appendChild(
    buildXmlElem(docElem, 'Liner', liner ? `${liner}` : `false`),
  );

  return taskElem;
}

function taskMultiSelectTable(docElem: XMLDocument, taskFormGroup: any) {
  const {
    taskType,
    id,
    number,
    mark,
    variantNumber,
    difficulty,
    text,
    answers,
    table,
    solution,
    showSolutionForTeacher,
    showSolutionForStudent,
    help,
    liner,
  } = taskFormGroup;

  const taskElem = docElem.createElement(taskType);

  taskElem.appendChild(buildXmlElem(docElem, 'Id', id));
  taskElem.appendChild(buildXmlElem(docElem, 'Number', number));
  taskElem.appendChild(buildXmlElem(docElem, 'Mark', mark));
  taskElem.appendChild(buildXmlElem(docElem, 'VariantNumber', variantNumber));
  taskElem.appendChild(buildXmlElem(docElem, 'Difficulty', difficulty));
  taskElem.appendChild(buildXmlElem(docElem, 'Text', escapeHtml(text)));
  taskElem.appendChild(buildXmlElem(docElem, 'Solution', escapeHtml(solution)));

  const rightAnswersElem = docElem.createElement('Answers');

  answers.forEach((answer: any) => {
    const elem = docElem.createElement('Answer');
    elem.appendChild(buildXmlElem(docElem, 'Id', escapeHtml(answer.id)));
    elem.appendChild(buildXmlElem(docElem, 'Text', escapeHtml(answer.text)));
    elem.appendChild(
      buildXmlElem(
        docElem,
        'IsKey',
        answer.isKey ? `${answer.isKey}` : `false`,
      ),
    );
    rightAnswersElem.appendChild(elem);
  });

  taskElem.appendChild(rightAnswersElem);

  taskElem.appendChild(buildXmlElem(docElem, 'Table', escapeHtml(table)));

  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ShowSolutionForTeacher',
      showSolutionForTeacher ? `${showSolutionForTeacher}` : `false`,
    ),
  );
  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ShowSolutionForStudent',
      showSolutionForStudent ? `${showSolutionForStudent}` : `false`,
    ),
  );
  taskElem.appendChild(buildXmlElem(docElem, 'Help', escapeHtml(help)));
  taskElem.appendChild(
    buildXmlElem(docElem, 'Liner', liner ? `${liner}` : `false`),
  );

  return taskElem;
}

function taskHtml5(docElem: XMLDocument, taskFormGroup: any) {
  const {
    taskType,
    id,
    number,
    mark,
    variantNumber,
    difficulty,
    solution,
    showSolutionForTeacher,
    showSolutionForStudent,
    help,
    liner,
  } = taskFormGroup;

  const taskElem = docElem.createElement(taskType);

  taskElem.appendChild(buildXmlElem(docElem, 'Id', id));
  taskElem.appendChild(buildXmlElem(docElem, 'Number', number));
  taskElem.appendChild(buildXmlElem(docElem, 'Mark', mark));
  taskElem.appendChild(buildXmlElem(docElem, 'VariantNumber', variantNumber));
  taskElem.appendChild(buildXmlElem(docElem, 'Difficulty', difficulty));

  taskElem.appendChild(buildXmlElem(docElem, 'Solution', escapeHtml(solution)));

  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ShowSolutionForTeacher',
      showSolutionForTeacher ? `${showSolutionForTeacher}` : `false`,
    ),
  );
  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ShowSolutionForStudent',
      showSolutionForStudent ? `${showSolutionForStudent}` : `false`,
    ),
  );
  taskElem.appendChild(buildXmlElem(docElem, 'Help', escapeHtml(help)));
  taskElem.appendChild(
    buildXmlElem(docElem, 'Liner', liner ? `${liner}` : `false`),
  );

  return taskElem;
}

function taskTimeLine(docElem: XMLDocument, taskFormGroup: any) {
  const {
    taskType,
    id,
    number,
    mark,
    variantNumber,
    difficulty,
    solution,
    showSolutionForTeacher,
    showSolutionForStudent,
    help,
    liner,
  } = taskFormGroup;

  const taskElem = docElem.createElement(taskType);

  taskElem.appendChild(buildXmlElem(docElem, 'Id', id));
  taskElem.appendChild(buildXmlElem(docElem, 'Number', number));
  taskElem.appendChild(buildXmlElem(docElem, 'Mark', mark));
  taskElem.appendChild(buildXmlElem(docElem, 'VariantNumber', variantNumber));
  taskElem.appendChild(buildXmlElem(docElem, 'Difficulty', difficulty));

  taskElem.appendChild(buildXmlElem(docElem, 'Solution', escapeHtml(solution)));

  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ShowSolutionForTeacher',
      showSolutionForTeacher ? `${showSolutionForTeacher}` : `false`,
    ),
  );
  taskElem.appendChild(
    buildXmlElem(
      docElem,
      'ShowSolutionForStudent',
      showSolutionForStudent ? `${showSolutionForStudent}` : `false`,
    ),
  );
  taskElem.appendChild(buildXmlElem(docElem, 'Help', escapeHtml(help)));
  taskElem.appendChild(
    buildXmlElem(docElem, 'Liner', liner ? `${liner}` : `false`),
  );

  return taskElem;
}

function buildXmlElem(docElem: any, name: string, value: string) {
  // console.log(name)
  const elem = docElem.createElement(name);
  elem.appendChild(docElem.createTextNode(value));
  return elem;
}
