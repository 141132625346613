import { fromJS } from 'immutable'
import { AnyAction } from 'redux'

import {
  CLOSE_FLOW_LOADER,
  OPEN_FLOW_LOADER,
  RESET_FLOW_LOADER,
} from './constants'

const initialState = fromJS({})

const loaderReducer = (state = initialState, { type, payload }: AnyAction) => {
  const id = payload && payload.id
  const path = Array.isArray(id) ? [...id, 'open'] : [id, 'open']

  switch (type) {
    case OPEN_FLOW_LOADER: {
      return state.setIn(path, true)
    }

    case CLOSE_FLOW_LOADER: {
      return state.setIn(path, false)
    }

    case RESET_FLOW_LOADER: {
      return state.remove(id)
    }
    default:
      return state
  }
}

export default loaderReducer
