import { AnyAction } from 'redux'
import { change } from 'redux-form/immutable'
import { all, put, takeEvery, takeLatest } from 'redux-saga/effects'

import { formCreateSuccess, formDataSuccess, formEditSuccess } from './actions'
import {
  FORM_CHANGE,
  FORM_CREATE_REQUEST,
  FORM_CREATE_SUCCESS,
  FORM_DATA_REQUEST,
  FORM_EDIT_REQUEST,
  FORM_EDIT_SUCCESS,
} from './constants'

function* get({ payload: { init, graphId, meta }, meta: { form } }: AnyAction) {
  if (init) {
    yield put(
      init({ payload: { callback: formDataSuccess, graphId, meta }, meta: { form } }),
    )
  }
}

function* edit({
  payload: { edit, fields, graphId, meta },
  meta: { form, onSuccess },
}: AnyAction) {
  if (edit) {
    yield put(
      edit({
        payload: { callback: formEditSuccess, fields, graphId, meta },
        meta: { form, onSuccess },
      }),
    )
  }
}

function* create({
  payload: { create, fields, graphId, meta },
  meta: { form, onSuccess },
}: AnyAction) {
  if (create) {
    yield put(
      create({
        payload: { callback: formCreateSuccess, fields, graphId, meta },
        meta: { form, onSuccess },
      }),
    )
  }
}

function* createSuccess({
  payload: { response },
  meta: { form, onSuccess },
}: AnyAction) {
  yield onSuccess({ payload: { response }, meta: { form, onSuccess } })
}

function* editSuccess({
  payload: { response },
  meta: { form, onSuccess },
}: AnyAction) {
  yield onSuccess({ payload: { response }, meta: { form, onSuccess } })
}

function* formChange({ payload: { data }, meta: { form } }: AnyAction) {
  for (const key in data) {
    yield put(change(form, key, data[key]))
  }
}

function* Saga() {
  yield all([
    takeEvery(FORM_DATA_REQUEST, get),
    takeEvery(FORM_EDIT_REQUEST, edit),
    takeEvery(FORM_CREATE_REQUEST, create),
    takeLatest(FORM_CREATE_SUCCESS, createSuccess),
    takeLatest(FORM_EDIT_SUCCESS, editSuccess),
    takeLatest(FORM_CHANGE, formChange),
  ])
}

export default Saga
