import NotFound from 'components/Errors/NotFound'
import Constructor from 'modules/Constructor/routes'

const rootRoutes = [
  ...Constructor,
  {
    path: '*',
    component: NotFound,
  },
]

export default rootRoutes
